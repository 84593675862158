"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PositionRequestToJSON = exports.PositionRequestFromJSONTyped = exports.PositionRequestFromJSON = void 0;
const runtime_1 = require("../runtime");
const MaterialPresentable_1 = require("./MaterialPresentable");
function PositionRequestFromJSON(json) {
    return PositionRequestFromJSONTyped(json, false);
}
exports.PositionRequestFromJSON = PositionRequestFromJSON;
function PositionRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
        'material': !runtime_1.exists(json, 'material') ? undefined : MaterialPresentable_1.MaterialPresentableFromJSON(json['material']),
    };
}
exports.PositionRequestFromJSONTyped = PositionRequestFromJSONTyped;
function PositionRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'material': MaterialPresentable_1.MaterialPresentableToJSON(value.material),
    };
}
exports.PositionRequestToJSON = PositionRequestToJSON;
