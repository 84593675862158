"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CertificationRequestToJSON = exports.CertificationRequestFromJSONTyped = exports.CertificationRequestFromJSON = exports.CertificationRequestSubjectEnum = void 0;
const runtime_1 = require("../runtime");
const DocumentRequest_1 = require("./DocumentRequest");
const MaterialPresentable_1 = require("./MaterialPresentable");
const ProcessType_1 = require("./ProcessType");
/**
* @export
* @enum {string}
*/
var CertificationRequestSubjectEnum;
(function (CertificationRequestSubjectEnum) {
    CertificationRequestSubjectEnum["Scope"] = "SCOPE";
    CertificationRequestSubjectEnum["Transaction"] = "TRANSACTION";
    CertificationRequestSubjectEnum["Material"] = "MATERIAL";
    CertificationRequestSubjectEnum["Self"] = "SELF";
})(CertificationRequestSubjectEnum = exports.CertificationRequestSubjectEnum || (exports.CertificationRequestSubjectEnum = {}));
function CertificationRequestFromJSON(json) {
    return CertificationRequestFromJSONTyped(json, false);
}
exports.CertificationRequestFromJSON = CertificationRequestFromJSON;
function CertificationRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'consigneeCompanyName': !runtime_1.exists(json, 'consigneeCompanyName') ? undefined : json['consigneeCompanyName'],
        'consigneeEmail': !runtime_1.exists(json, 'consigneeEmail') ? undefined : json['consigneeEmail'],
        'validFrom': !runtime_1.exists(json, 'validFrom') ? undefined : (new Date(json['validFrom'])),
        'validUntil': !runtime_1.exists(json, 'validUntil') ? undefined : (new Date(json['validUntil'])),
        'documentTypeCode': !runtime_1.exists(json, 'documentTypeCode') ? undefined : json['documentTypeCode'],
        'documentUpload': !runtime_1.exists(json, 'documentUpload') ? undefined : DocumentRequest_1.DocumentRequestFromJSON(json['documentUpload']),
        'processingStandardName': !runtime_1.exists(json, 'processingStandardName') ? undefined : json['processingStandardName'],
        'notes': !runtime_1.exists(json, 'notes') ? undefined : json['notes'],
        'contractorCompanyName': !runtime_1.exists(json, 'contractorCompanyName') ? undefined : json['contractorCompanyName'],
        'contractorEmail': !runtime_1.exists(json, 'contractorEmail') ? undefined : json['contractorEmail'],
        'assessmentName': !runtime_1.exists(json, 'assessmentName') ? undefined : json['assessmentName'],
        'productCategoryCodeList': !runtime_1.exists(json, 'productCategoryCodeList') ? undefined : json['productCategoryCodeList'],
        'outputMaterials': !runtime_1.exists(json, 'outputMaterials') ? undefined : (json['outputMaterials'].map(MaterialPresentable_1.MaterialPresentableFromJSON)),
        'processTypes': !runtime_1.exists(json, 'processTypes') ? undefined : (json['processTypes'].map(ProcessType_1.ProcessTypeFromJSON)),
        'shippingReferenceNumbers': !runtime_1.exists(json, 'shippingReferenceNumbers') ? undefined : json['shippingReferenceNumbers'],
        'certificateReferenceNumber': !runtime_1.exists(json, 'certificateReferenceNumber') ? undefined : json['certificateReferenceNumber'],
        'material': !runtime_1.exists(json, 'material') ? undefined : MaterialPresentable_1.MaterialPresentableFromJSON(json['material']),
        'subject': !runtime_1.exists(json, 'subject') ? undefined : json['subject'],
        'certificatePageUrl': !runtime_1.exists(json, 'certificatePageUrl') ? undefined : json['certificatePageUrl'],
        'invitation': !runtime_1.exists(json, 'invitation') ? undefined : json['invitation'],
    };
}
exports.CertificationRequestFromJSONTyped = CertificationRequestFromJSONTyped;
function CertificationRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'consigneeCompanyName': value.consigneeCompanyName,
        'consigneeEmail': value.consigneeEmail,
        'validFrom': value.validFrom === undefined ? undefined : (value.validFrom.toISOString()),
        'validUntil': value.validUntil === undefined ? undefined : (value.validUntil.toISOString()),
        'documentTypeCode': value.documentTypeCode,
        'documentUpload': DocumentRequest_1.DocumentRequestToJSON(value.documentUpload),
        'processingStandardName': value.processingStandardName,
        'notes': value.notes,
        'contractorCompanyName': value.contractorCompanyName,
        'contractorEmail': value.contractorEmail,
        'assessmentName': value.assessmentName,
        'productCategoryCodeList': value.productCategoryCodeList,
        'outputMaterials': value.outputMaterials === undefined ? undefined : (value.outputMaterials.map(MaterialPresentable_1.MaterialPresentableToJSON)),
        'processTypes': value.processTypes === undefined ? undefined : (value.processTypes.map(ProcessType_1.ProcessTypeToJSON)),
        'shippingReferenceNumbers': value.shippingReferenceNumbers,
        'certificateReferenceNumber': value.certificateReferenceNumber,
        'material': MaterialPresentable_1.MaterialPresentableToJSON(value.material),
        'subject': value.subject,
        'certificatePageUrl': value.certificatePageUrl,
        'invitation': value.invitation,
    };
}
exports.CertificationRequestToJSON = CertificationRequestToJSON;
