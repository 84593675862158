"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CertificationTransactionToJSON = exports.CertificationTransactionFromJSONTyped = exports.CertificationTransactionFromJSON = exports.CertificationTransactionSubjectEnum = exports.CertificationTransactionStatusEnum = void 0;
const runtime_1 = require("../runtime");
const AssessmentType_1 = require("./AssessmentType");
const CertificationTransactionProcessType_1 = require("./CertificationTransactionProcessType");
const CertificationTransactionProductCategory_1 = require("./CertificationTransactionProductCategory");
const Company_1 = require("./Company");
const Document_1 = require("./Document");
const DocumentType_1 = require("./DocumentType");
const Material_1 = require("./Material");
const ProcessingStandard_1 = require("./ProcessingStandard");
/**
* @export
* @enum {string}
*/
var CertificationTransactionStatusEnum;
(function (CertificationTransactionStatusEnum) {
    CertificationTransactionStatusEnum["Pending"] = "PENDING";
    CertificationTransactionStatusEnum["Accepted"] = "ACCEPTED";
    CertificationTransactionStatusEnum["Refused"] = "REFUSED";
})(CertificationTransactionStatusEnum = exports.CertificationTransactionStatusEnum || (exports.CertificationTransactionStatusEnum = {})); /**
* @export
* @enum {string}
*/
var CertificationTransactionSubjectEnum;
(function (CertificationTransactionSubjectEnum) {
    CertificationTransactionSubjectEnum["Scope"] = "SCOPE";
    CertificationTransactionSubjectEnum["Transaction"] = "TRANSACTION";
    CertificationTransactionSubjectEnum["Material"] = "MATERIAL";
    CertificationTransactionSubjectEnum["Self"] = "SELF";
})(CertificationTransactionSubjectEnum = exports.CertificationTransactionSubjectEnum || (exports.CertificationTransactionSubjectEnum = {}));
function CertificationTransactionFromJSON(json) {
    return CertificationTransactionFromJSONTyped(json, false);
}
exports.CertificationTransactionFromJSON = CertificationTransactionFromJSON;
function CertificationTransactionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
        'contractorReferenceNumber': !runtime_1.exists(json, 'contractorReferenceNumber') ? undefined : json['contractorReferenceNumber'],
        'consigneeReferenceNumber': !runtime_1.exists(json, 'consigneeReferenceNumber') ? undefined : json['consigneeReferenceNumber'],
        'contractor': !runtime_1.exists(json, 'contractor') ? undefined : Company_1.CompanyFromJSON(json['contractor']),
        'consignee': !runtime_1.exists(json, 'consignee') ? undefined : Company_1.CompanyFromJSON(json['consignee']),
        'approver': !runtime_1.exists(json, 'approver') ? undefined : Company_1.CompanyFromJSON(json['approver']),
        'contractorEmail': !runtime_1.exists(json, 'contractorEmail') ? undefined : json['contractorEmail'],
        'consigneeEmail': !runtime_1.exists(json, 'consigneeEmail') ? undefined : json['consigneeEmail'],
        'validFrom': !runtime_1.exists(json, 'validFrom') ? undefined : (new Date(json['validFrom'])),
        'validUntil': !runtime_1.exists(json, 'validUntil') ? undefined : (new Date(json['validUntil'])),
        'document': !runtime_1.exists(json, 'document') ? undefined : Document_1.DocumentFromJSON(json['document']),
        'documentType': !runtime_1.exists(json, 'documentType') ? undefined : DocumentType_1.DocumentTypeFromJSON(json['documentType']),
        'notes': !runtime_1.exists(json, 'notes') ? undefined : json['notes'],
        'consigneeDate': !runtime_1.exists(json, 'consigneeDate') ? undefined : (new Date(json['consigneeDate'])),
        'contractorDate': !runtime_1.exists(json, 'contractorDate') ? undefined : (new Date(json['contractorDate'])),
        'status': !runtime_1.exists(json, 'status') ? undefined : json['status'],
        'processingStandard': !runtime_1.exists(json, 'processingStandard') ? undefined : ProcessingStandard_1.ProcessingStandardFromJSON(json['processingStandard']),
        'assessmentType': !runtime_1.exists(json, 'assessmentType') ? undefined : AssessmentType_1.AssessmentTypeFromJSON(json['assessmentType']),
        'processTypes': !runtime_1.exists(json, 'processTypes') ? undefined : (json['processTypes'].map(CertificationTransactionProcessType_1.CertificationTransactionProcessTypeFromJSON)),
        'productCategories': !runtime_1.exists(json, 'productCategories') ? undefined : (json['productCategories'].map(CertificationTransactionProductCategory_1.CertificationTransactionProductCategoryFromJSON)),
        'certificateReferenceNumber': !runtime_1.exists(json, 'certificateReferenceNumber') ? undefined : json['certificateReferenceNumber'],
        'subject': !runtime_1.exists(json, 'subject') ? undefined : json['subject'],
        'material': !runtime_1.exists(json, 'material') ? undefined : Material_1.MaterialFromJSON(json['material']),
        'certificatePageUrl': !runtime_1.exists(json, 'certificatePageUrl') ? undefined : json['certificatePageUrl'],
        'outputMaterials': !runtime_1.exists(json, 'outputMaterials') ? undefined : (new Set(json['outputMaterials'].map(Material_1.MaterialFromJSON))),
    };
}
exports.CertificationTransactionFromJSONTyped = CertificationTransactionFromJSONTyped;
function CertificationTransactionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'contractorReferenceNumber': value.contractorReferenceNumber,
        'consigneeReferenceNumber': value.consigneeReferenceNumber,
        'contractor': Company_1.CompanyToJSON(value.contractor),
        'consignee': Company_1.CompanyToJSON(value.consignee),
        'approver': Company_1.CompanyToJSON(value.approver),
        'contractorEmail': value.contractorEmail,
        'consigneeEmail': value.consigneeEmail,
        'validFrom': value.validFrom === undefined ? undefined : (value.validFrom.toISOString()),
        'validUntil': value.validUntil === undefined ? undefined : (value.validUntil.toISOString()),
        'document': Document_1.DocumentToJSON(value.document),
        'documentType': DocumentType_1.DocumentTypeToJSON(value.documentType),
        'notes': value.notes,
        'consigneeDate': value.consigneeDate === undefined ? undefined : (value.consigneeDate.toISOString()),
        'contractorDate': value.contractorDate === undefined ? undefined : (value.contractorDate.toISOString()),
        'status': value.status,
        'processingStandard': ProcessingStandard_1.ProcessingStandardToJSON(value.processingStandard),
        'assessmentType': AssessmentType_1.AssessmentTypeToJSON(value.assessmentType),
        'processTypes': value.processTypes === undefined ? undefined : (value.processTypes.map(CertificationTransactionProcessType_1.CertificationTransactionProcessTypeToJSON)),
        'productCategories': value.productCategories === undefined ? undefined : (value.productCategories.map(CertificationTransactionProductCategory_1.CertificationTransactionProductCategoryToJSON)),
        'certificateReferenceNumber': value.certificateReferenceNumber,
        'subject': value.subject,
        'material': Material_1.MaterialToJSON(value.material),
        'certificatePageUrl': value.certificatePageUrl,
        'outputMaterials': value.outputMaterials === undefined ? undefined : (Array.from(value.outputMaterials).map(Material_1.MaterialToJSON)),
    };
}
exports.CertificationTransactionToJSON = CertificationTransactionToJSON;
