"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConfirmationTransactionRequestToJSON = exports.ConfirmationTransactionRequestFromJSONTyped = exports.ConfirmationTransactionRequestFromJSON = exports.ConfirmationTransactionRequestTransactionStatusEnum = void 0;
const runtime_1 = require("../runtime");
const PositionRequest_1 = require("./PositionRequest");
/**
* @export
* @enum {string}
*/
var ConfirmationTransactionRequestTransactionStatusEnum;
(function (ConfirmationTransactionRequestTransactionStatusEnum) {
    ConfirmationTransactionRequestTransactionStatusEnum["Pending"] = "PENDING";
    ConfirmationTransactionRequestTransactionStatusEnum["Accepted"] = "ACCEPTED";
    ConfirmationTransactionRequestTransactionStatusEnum["Refused"] = "REFUSED";
})(ConfirmationTransactionRequestTransactionStatusEnum = exports.ConfirmationTransactionRequestTransactionStatusEnum || (exports.ConfirmationTransactionRequestTransactionStatusEnum = {}));
function ConfirmationTransactionRequestFromJSON(json) {
    return ConfirmationTransactionRequestFromJSONTyped(json, false);
}
exports.ConfirmationTransactionRequestFromJSON = ConfirmationTransactionRequestFromJSON;
function ConfirmationTransactionRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'consigneeReferenceNumber': !runtime_1.exists(json, 'consigneeReferenceNumber') ? undefined : json['consigneeReferenceNumber'],
        'certificationReferenceNumber': !runtime_1.exists(json, 'certificationReferenceNumber') ? undefined : json['certificationReferenceNumber'],
        'transactionStatus': !runtime_1.exists(json, 'transactionStatus') ? undefined : json['transactionStatus'],
        'consigneeDate': !runtime_1.exists(json, 'consigneeDate') ? undefined : (new Date(json['consigneeDate'])),
        'positions': !runtime_1.exists(json, 'positions') ? undefined : (json['positions'].map(PositionRequest_1.PositionRequestFromJSON)),
    };
}
exports.ConfirmationTransactionRequestFromJSONTyped = ConfirmationTransactionRequestFromJSONTyped;
function ConfirmationTransactionRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'consigneeReferenceNumber': value.consigneeReferenceNumber,
        'certificationReferenceNumber': value.certificationReferenceNumber,
        'transactionStatus': value.transactionStatus,
        'consigneeDate': value.consigneeDate === undefined ? undefined : (value.consigneeDate.toISOString()),
        'positions': value.positions === undefined ? undefined : (value.positions.map(PositionRequest_1.PositionRequestToJSON)),
    };
}
exports.ConfirmationTransactionRequestToJSON = ConfirmationTransactionRequestToJSON;
