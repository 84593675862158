"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TradeControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class TradeControllerApi extends runtime.BaseAPI {
    /**
     * Create a new contract
     */
    createContractRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.contractRequest === null || requestParameters.contractRequest === undefined) {
                throw new runtime.RequiredError('contractRequest', 'Required parameter requestParameters.contractRequest was null or undefined when calling createContract.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearerAuth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/trades/contract/create`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.ContractRequestToJSON(requestParameters.contractRequest),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.TradePresentableFromJSON(jsonValue));
        });
    }
    /**
     * Create a new contract
     */
    createContract(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.createContractRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Create a new order
     */
    createOrderRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orderRequest === null || requestParameters.orderRequest === undefined) {
                throw new runtime.RequiredError('orderRequest', 'Required parameter requestParameters.orderRequest was null or undefined when calling createOrder.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearerAuth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/trades/order/create`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.OrderRequestToJSON(requestParameters.orderRequest),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.TradePresentableFromJSON(jsonValue));
        });
    }
    /**
     * Create a new order
     */
    createOrder(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.createOrderRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Create a new contract
     */
    createShippingRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.shippingRequest === null || requestParameters.shippingRequest === undefined) {
                throw new runtime.RequiredError('shippingRequest', 'Required parameter requestParameters.shippingRequest was null or undefined when calling createShipping.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearerAuth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/trades/shipping/create`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.ShippingRequestToJSON(requestParameters.shippingRequest),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.TradePresentableFromJSON(jsonValue));
        });
    }
    /**
     * Create a new contract
     */
    createShipping(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.createShippingRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    getContractByIdRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getContractById.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/trades/contracts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ConfirmationTradePresentableFromJSON(jsonValue));
        });
    }
    /**
     */
    getContractById(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getContractByIdRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    getContractsRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/trades/contracts`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.TableTradePresentableFromJSON));
        });
    }
    /**
     */
    getContracts(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getContractsRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    getOrderByIdRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getOrderById.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/trades/orders/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ConfirmationTradePresentableFromJSON(jsonValue));
        });
    }
    /**
     */
    getOrderById(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getOrderByIdRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    getOrdersRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/trades/orders`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.TableTradePresentableFromJSON));
        });
    }
    /**
     */
    getOrders(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getOrdersRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    getShippingByIdRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getShippingById.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/trades/shippings/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ConfirmationTradePresentableFromJSON(jsonValue));
        });
    }
    /**
     */
    getShippingById(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getShippingByIdRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    getShippingsRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/trades/shippings`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.TableTradePresentableFromJSON));
        });
    }
    /**
     */
    getShippings(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getShippingsRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    getShippingsByCompanyRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.companyName === null || requestParameters.companyName === undefined) {
                throw new runtime.RequiredError('companyName', 'Required parameter requestParameters.companyName was null or undefined when calling getShippingsByCompany.');
            }
            const queryParameters = {};
            if (requestParameters.companyName !== undefined) {
                queryParameters['companyName'] = requestParameters.companyName;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/trades/shippingsByCompany`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.TradePresentableFromJSON));
        });
    }
    /**
     */
    getShippingsByCompany(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getShippingsByCompanyRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    getTradeProcessingStandardsRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/trades/processingStandards`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.ProcessingStandardPresentableFromJSON));
        });
    }
    /**
     */
    getTradeProcessingStandards(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getTradeProcessingStandardsRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     * Update a trade
     */
    updateTradeRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling updateTrade.');
            }
            if (requestParameters.updateTradeRequest === null || requestParameters.updateTradeRequest === undefined) {
                throw new runtime.RequiredError('updateTradeRequest', 'Required parameter requestParameters.updateTradeRequest was null or undefined when calling updateTrade.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearerAuth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/trades/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.UpdateTradeRequestToJSON(requestParameters.updateTradeRequest),
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Update a trade
     */
    updateTrade(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.updateTradeRaw(requestParameters, initOverrides);
        });
    }
}
exports.TradeControllerApi = TradeControllerApi;
