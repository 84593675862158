"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PositionPresentableToJSON = exports.PositionPresentableFromJSONTyped = exports.PositionPresentableFromJSON = void 0;
const runtime_1 = require("../runtime");
function PositionPresentableFromJSON(json) {
    return PositionPresentableFromJSONTyped(json, false);
}
exports.PositionPresentableFromJSON = PositionPresentableFromJSON;
function PositionPresentableFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
        'contractorMaterialName': !runtime_1.exists(json, 'contractorMaterialName') ? undefined : json['contractorMaterialName'],
        'contractorMaterialId': !runtime_1.exists(json, 'contractorMaterialId') ? undefined : json['contractorMaterialId'],
        'consigneeMaterialName': !runtime_1.exists(json, 'consigneeMaterialName') ? undefined : json['consigneeMaterialName'],
        'consigneeMaterialId': !runtime_1.exists(json, 'consigneeMaterialId') ? undefined : json['consigneeMaterialId'],
        'quantity': !runtime_1.exists(json, 'quantity') ? undefined : json['quantity'],
        'weight': !runtime_1.exists(json, 'weight') ? undefined : json['weight'],
        'externalDescription': !runtime_1.exists(json, 'externalDescription') ? undefined : json['externalDescription'],
        'unit': !runtime_1.exists(json, 'unit') ? undefined : json['unit'],
        'contractorSupplierName': !runtime_1.exists(json, 'contractorSupplierName') ? undefined : json['contractorSupplierName'],
    };
}
exports.PositionPresentableFromJSONTyped = PositionPresentableFromJSONTyped;
function PositionPresentableToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'contractorMaterialName': value.contractorMaterialName,
        'contractorMaterialId': value.contractorMaterialId,
        'consigneeMaterialName': value.consigneeMaterialName,
        'consigneeMaterialId': value.consigneeMaterialId,
        'quantity': value.quantity,
        'weight': value.weight,
        'externalDescription': value.externalDescription,
        'unit': value.unit,
        'contractorSupplierName': value.contractorSupplierName,
    };
}
exports.PositionPresentableToJSON = PositionPresentableToJSON;
