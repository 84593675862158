"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SupplyChainCompanyInfoPresentableToJSON = exports.SupplyChainCompanyInfoPresentableFromJSONTyped = exports.SupplyChainCompanyInfoPresentableFromJSON = void 0;
const runtime_1 = require("../runtime");
function SupplyChainCompanyInfoPresentableFromJSON(json) {
    return SupplyChainCompanyInfoPresentableFromJSONTyped(json, false);
}
exports.SupplyChainCompanyInfoPresentableFromJSON = SupplyChainCompanyInfoPresentableFromJSON;
function SupplyChainCompanyInfoPresentableFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !runtime_1.exists(json, 'name') ? undefined : json['name'],
        'visibleName': !runtime_1.exists(json, 'visibleName') ? undefined : json['visibleName'],
        'location': !runtime_1.exists(json, 'location') ? undefined : json['location'],
        'country': !runtime_1.exists(json, 'country') ? undefined : json['country'],
        'region': !runtime_1.exists(json, 'region') ? undefined : json['region'],
        'partnerType': !runtime_1.exists(json, 'partnerType') ? undefined : json['partnerType'],
    };
}
exports.SupplyChainCompanyInfoPresentableFromJSONTyped = SupplyChainCompanyInfoPresentableFromJSONTyped;
function SupplyChainCompanyInfoPresentableToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'visibleName': value.visibleName,
        'location': value.location,
        'country': value.country,
        'region': value.region,
        'partnerType': value.partnerType,
    };
}
exports.SupplyChainCompanyInfoPresentableToJSON = SupplyChainCompanyInfoPresentableToJSON;
