"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SupplyChainTradePresentableToJSON = exports.SupplyChainTradePresentableFromJSONTyped = exports.SupplyChainTradePresentableFromJSON = void 0;
const runtime_1 = require("../runtime");
const SupplyChainCertificatePresentable_1 = require("./SupplyChainCertificatePresentable");
function SupplyChainTradePresentableFromJSON(json) {
    return SupplyChainTradePresentableFromJSONTyped(json, false);
}
exports.SupplyChainTradePresentableFromJSON = SupplyChainTradePresentableFromJSON;
function SupplyChainTradePresentableFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': !runtime_1.exists(json, 'type') ? undefined : json['type'],
        'referenceNumber': !runtime_1.exists(json, 'referenceNumber') ? undefined : json['referenceNumber'],
        'creationDate': !runtime_1.exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
        'issueDate': !runtime_1.exists(json, 'issueDate') ? undefined : (new Date(json['issueDate'])),
        'validUntilDate': !runtime_1.exists(json, 'validUntilDate') ? undefined : (new Date(json['validUntilDate'])),
        'consigneeToContractorMaterialMap': !runtime_1.exists(json, 'consignee_to_contractor_material_map') ? undefined : json['consignee_to_contractor_material_map'],
        'documentId': !runtime_1.exists(json, 'documentId') ? undefined : json['documentId'],
        'documentFileName': !runtime_1.exists(json, 'documentFileName') ? undefined : json['documentFileName'],
        'documentType': !runtime_1.exists(json, 'documentType') ? undefined : json['documentType'],
        'processingStandards': !runtime_1.exists(json, 'processingStandards') ? undefined : json['processingStandards'],
        'certificates': !runtime_1.exists(json, 'certificates') ? undefined : (json['certificates'].map(SupplyChainCertificatePresentable_1.SupplyChainCertificatePresentableFromJSON)),
    };
}
exports.SupplyChainTradePresentableFromJSONTyped = SupplyChainTradePresentableFromJSONTyped;
function SupplyChainTradePresentableToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': value.type,
        'referenceNumber': value.referenceNumber,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
        'issueDate': value.issueDate === undefined ? undefined : (value.issueDate.toISOString()),
        'validUntilDate': value.validUntilDate === undefined ? undefined : (value.validUntilDate.toISOString()),
        'consignee_to_contractor_material_map': value.consigneeToContractorMaterialMap,
        'documentId': value.documentId,
        'documentFileName': value.documentFileName,
        'documentType': value.documentType,
        'processingStandards': value.processingStandards,
        'certificates': value.certificates === undefined ? undefined : (value.certificates.map(SupplyChainCertificatePresentable_1.SupplyChainCertificatePresentableToJSON)),
    };
}
exports.SupplyChainTradePresentableToJSON = SupplyChainTradePresentableToJSON;
