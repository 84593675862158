"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableTradePresentableToJSON = exports.TableTradePresentableFromJSONTyped = exports.TableTradePresentableFromJSON = exports.TableTradePresentableStatusEnum = void 0;
const runtime_1 = require("../runtime");
const PositionPresentable_1 = require("./PositionPresentable");
/**
* @export
* @enum {string}
*/
var TableTradePresentableStatusEnum;
(function (TableTradePresentableStatusEnum) {
    TableTradePresentableStatusEnum["Pending"] = "PENDING";
    TableTradePresentableStatusEnum["Accepted"] = "ACCEPTED";
    TableTradePresentableStatusEnum["Refused"] = "REFUSED";
})(TableTradePresentableStatusEnum = exports.TableTradePresentableStatusEnum || (exports.TableTradePresentableStatusEnum = {}));
function TableTradePresentableFromJSON(json) {
    return TableTradePresentableFromJSONTyped(json, false);
}
exports.TableTradePresentableFromJSON = TableTradePresentableFromJSON;
function TableTradePresentableFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
        'contractorName': !runtime_1.exists(json, 'contractorName') ? undefined : json['contractorName'],
        'consigneeName': !runtime_1.exists(json, 'consigneeName') ? undefined : json['consigneeName'],
        'approverName': !runtime_1.exists(json, 'approverName') ? undefined : json['approverName'],
        'validFrom': !runtime_1.exists(json, 'validFrom') ? undefined : (new Date(json['validFrom'])),
        'validUntil': !runtime_1.exists(json, 'validUntil') ? undefined : (new Date(json['validUntil'])),
        'documentType': !runtime_1.exists(json, 'documentType') ? undefined : json['documentType'],
        'status': !runtime_1.exists(json, 'status') ? undefined : json['status'],
        'contractorReferenceNumber': !runtime_1.exists(json, 'contractorReferenceNumber') ? undefined : json['contractorReferenceNumber'],
        'positions': !runtime_1.exists(json, 'positions') ? undefined : (json['positions'].map(PositionPresentable_1.PositionPresentableFromJSON)),
    };
}
exports.TableTradePresentableFromJSONTyped = TableTradePresentableFromJSONTyped;
function TableTradePresentableToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'contractorName': value.contractorName,
        'consigneeName': value.consigneeName,
        'approverName': value.approverName,
        'validFrom': value.validFrom === undefined ? undefined : (value.validFrom.toISOString()),
        'validUntil': value.validUntil === undefined ? undefined : (value.validUntil.toISOString()),
        'documentType': value.documentType,
        'status': value.status,
        'contractorReferenceNumber': value.contractorReferenceNumber,
        'positions': value.positions === undefined ? undefined : (value.positions.map(PositionPresentable_1.PositionPresentableToJSON)),
    };
}
exports.TableTradePresentableToJSON = TableTradePresentableToJSON;
