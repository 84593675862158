"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransformationPlanUpdateRequestToJSON = exports.TransformationPlanUpdateRequestFromJSONTyped = exports.TransformationPlanUpdateRequestFromJSON = void 0;
const runtime_1 = require("../runtime");
function TransformationPlanUpdateRequestFromJSON(json) {
    return TransformationPlanUpdateRequestFromJSONTyped(json, false);
}
exports.TransformationPlanUpdateRequestFromJSON = TransformationPlanUpdateRequestFromJSON;
function TransformationPlanUpdateRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'processingStandardList': !runtime_1.exists(json, 'processingStandardList') ? undefined : json['processingStandardList'],
        'traceabilityLevelName': !runtime_1.exists(json, 'traceabilityLevelName') ? undefined : json['traceabilityLevelName'],
        'transparencyLevelName': !runtime_1.exists(json, 'transparencyLevelName') ? undefined : json['transparencyLevelName'],
    };
}
exports.TransformationPlanUpdateRequestFromJSONTyped = TransformationPlanUpdateRequestFromJSONTyped;
function TransformationPlanUpdateRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'processingStandardList': value.processingStandardList,
        'traceabilityLevelName': value.traceabilityLevelName,
        'transparencyLevelName': value.transparencyLevelName,
    };
}
exports.TransformationPlanUpdateRequestToJSON = TransformationPlanUpdateRequestToJSON;
