"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TradePresentableToJSON = exports.TradePresentableFromJSONTyped = exports.TradePresentableFromJSON = void 0;
const runtime_1 = require("../runtime");
const PositionPresentable_1 = require("./PositionPresentable");
function TradePresentableFromJSON(json) {
    return TradePresentableFromJSONTyped(json, false);
}
exports.TradePresentableFromJSON = TradePresentableFromJSON;
function TradePresentableFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'contractorReferenceNumber': !runtime_1.exists(json, 'contractorReferenceNumber') ? undefined : json['contractorReferenceNumber'],
        'documentID': !runtime_1.exists(json, 'documentID') ? undefined : json['documentID'],
        'positions': !runtime_1.exists(json, 'positions') ? undefined : (json['positions'].map(PositionPresentable_1.PositionPresentableFromJSON)),
        'contractorName': !runtime_1.exists(json, 'contractorName') ? undefined : json['contractorName'],
        'contractorEmail': !runtime_1.exists(json, 'contractorEmail') ? undefined : json['contractorEmail'],
        'consigneeName': !runtime_1.exists(json, 'consigneeName') ? undefined : json['consigneeName'],
        'documentType': !runtime_1.exists(json, 'documentType') ? undefined : json['documentType'],
        'notes': !runtime_1.exists(json, 'notes') ? undefined : json['notes'],
        'validFrom': !runtime_1.exists(json, 'validFrom') ? undefined : (new Date(json['validFrom'])),
        'validUntil': !runtime_1.exists(json, 'validUntil') ? undefined : (new Date(json['validUntil'])),
        'certified': !runtime_1.exists(json, 'certified') ? undefined : json['certified'],
    };
}
exports.TradePresentableFromJSONTyped = TradePresentableFromJSONTyped;
function TradePresentableToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'contractorReferenceNumber': value.contractorReferenceNumber,
        'documentID': value.documentID,
        'positions': value.positions === undefined ? undefined : (value.positions.map(PositionPresentable_1.PositionPresentableToJSON)),
        'contractorName': value.contractorName,
        'contractorEmail': value.contractorEmail,
        'consigneeName': value.consigneeName,
        'documentType': value.documentType,
        'notes': value.notes,
        'validFrom': value.validFrom === undefined ? undefined : (value.validFrom.toISOString()),
        'validUntil': value.validUntil === undefined ? undefined : (value.validUntil.toISOString()),
        'certified': value.certified,
    };
}
exports.TradePresentableToJSON = TradePresentableToJSON;
