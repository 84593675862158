"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateTradeRequestToJSON = exports.UpdateTradeRequestFromJSONTyped = exports.UpdateTradeRequestFromJSON = void 0;
const runtime_1 = require("../runtime");
function UpdateTradeRequestFromJSON(json) {
    return UpdateTradeRequestFromJSONTyped(json, false);
}
exports.UpdateTradeRequestFromJSON = UpdateTradeRequestFromJSON;
function UpdateTradeRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'processingStandardName': !runtime_1.exists(json, 'processingStandardName') ? undefined : json['processingStandardName'],
        'tradeType': !runtime_1.exists(json, 'tradeType') ? undefined : json['tradeType'],
    };
}
exports.UpdateTradeRequestFromJSONTyped = UpdateTradeRequestFromJSONTyped;
function UpdateTradeRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'processingStandardName': value.processingStandardName,
        'tradeType': value.tradeType,
    };
}
exports.UpdateTradeRequestToJSON = UpdateTradeRequestToJSON;
