"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DocumentPresentableToJSON = exports.DocumentPresentableFromJSONTyped = exports.DocumentPresentableFromJSON = void 0;
const runtime_1 = require("../runtime");
function DocumentPresentableFromJSON(json) {
    return DocumentPresentableFromJSONTyped(json, false);
}
exports.DocumentPresentableFromJSON = DocumentPresentableFromJSON;
function DocumentPresentableFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'contentType': !runtime_1.exists(json, 'contentType') ? undefined : json['contentType'],
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
        'content': !runtime_1.exists(json, 'content') ? undefined : json['content'],
        'fileName': !runtime_1.exists(json, 'fileName') ? undefined : json['fileName'],
    };
}
exports.DocumentPresentableFromJSONTyped = DocumentPresentableFromJSONTyped;
function DocumentPresentableToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'contentType': value.contentType,
        'id': value.id,
        'content': value.content,
        'fileName': value.fileName,
    };
}
exports.DocumentPresentableToJSON = DocumentPresentableToJSON;
