"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransformationPlanPositionRequestToJSON = exports.TransformationPlanPositionRequestFromJSONTyped = exports.TransformationPlanPositionRequestFromJSON = void 0;
const runtime_1 = require("../runtime");
function TransformationPlanPositionRequestFromJSON(json) {
    return TransformationPlanPositionRequestFromJSONTyped(json, false);
}
exports.TransformationPlanPositionRequestFromJSON = TransformationPlanPositionRequestFromJSON;
function TransformationPlanPositionRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'contractorMaterialId': !runtime_1.exists(json, 'contractorMaterialId') ? undefined : json['contractorMaterialId'],
        'quantity': !runtime_1.exists(json, 'quantity') ? undefined : json['quantity'],
    };
}
exports.TransformationPlanPositionRequestFromJSONTyped = TransformationPlanPositionRequestFromJSONTyped;
function TransformationPlanPositionRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'contractorMaterialId': value.contractorMaterialId,
        'quantity': value.quantity,
    };
}
exports.TransformationPlanPositionRequestToJSON = TransformationPlanPositionRequestToJSON;
