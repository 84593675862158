"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DocumentTypeToJSON = exports.DocumentTypeFromJSONTyped = exports.DocumentTypeFromJSON = void 0;
const runtime_1 = require("../runtime");
function DocumentTypeFromJSON(json) {
    return DocumentTypeFromJSONTyped(json, false);
}
exports.DocumentTypeFromJSON = DocumentTypeFromJSON;
function DocumentTypeFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'code': !runtime_1.exists(json, 'code') ? undefined : json['code'],
        'name': !runtime_1.exists(json, 'name') ? undefined : json['name'],
        'description': !runtime_1.exists(json, 'description') ? undefined : json['description'],
    };
}
exports.DocumentTypeFromJSONTyped = DocumentTypeFromJSONTyped;
function DocumentTypeToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'code': value.code,
        'name': value.name,
        'description': value.description,
    };
}
exports.DocumentTypeToJSON = DocumentTypeToJSON;
