"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CertificationTransactionProductCategoryToJSON = exports.CertificationTransactionProductCategoryFromJSONTyped = exports.CertificationTransactionProductCategoryFromJSON = void 0;
const runtime_1 = require("../runtime");
const CertificationTransaction_1 = require("./CertificationTransaction");
const ProductCategory_1 = require("./ProductCategory");
function CertificationTransactionProductCategoryFromJSON(json) {
    return CertificationTransactionProductCategoryFromJSONTyped(json, false);
}
exports.CertificationTransactionProductCategoryFromJSON = CertificationTransactionProductCategoryFromJSON;
function CertificationTransactionProductCategoryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
        'certificationTransaction': !runtime_1.exists(json, 'certificationTransaction') ? undefined : CertificationTransaction_1.CertificationTransactionFromJSON(json['certificationTransaction']),
        'productCategory': !runtime_1.exists(json, 'productCategory') ? undefined : ProductCategory_1.ProductCategoryFromJSON(json['productCategory']),
    };
}
exports.CertificationTransactionProductCategoryFromJSONTyped = CertificationTransactionProductCategoryFromJSONTyped;
function CertificationTransactionProductCategoryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'certificationTransaction': CertificationTransaction_1.CertificationTransactionToJSON(value.certificationTransaction),
        'productCategory': ProductCategory_1.ProductCategoryToJSON(value.productCategory),
    };
}
exports.CertificationTransactionProductCategoryToJSON = CertificationTransactionProductCategoryToJSON;
