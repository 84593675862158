"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProcessingStandardToJSON = exports.ProcessingStandardFromJSONTyped = exports.ProcessingStandardFromJSON = void 0;
const runtime_1 = require("../runtime");
const SustainabilityCriterion_1 = require("./SustainabilityCriterion");
function ProcessingStandardFromJSON(json) {
    return ProcessingStandardFromJSONTyped(json, false);
}
exports.ProcessingStandardFromJSON = ProcessingStandardFromJSON;
function ProcessingStandardFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !runtime_1.exists(json, 'name') ? undefined : json['name'],
        'logoPath': !runtime_1.exists(json, 'logoPath') ? undefined : json['logoPath'],
        'siteUrl': !runtime_1.exists(json, 'siteUrl') ? undefined : json['siteUrl'],
        'sustainabilityCriterion': !runtime_1.exists(json, 'sustainabilityCriterion') ? undefined : SustainabilityCriterion_1.SustainabilityCriterionFromJSON(json['sustainabilityCriterion']),
    };
}
exports.ProcessingStandardFromJSONTyped = ProcessingStandardFromJSONTyped;
function ProcessingStandardToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'logoPath': value.logoPath,
        'siteUrl': value.siteUrl,
        'sustainabilityCriterion': SustainabilityCriterion_1.SustainabilityCriterionToJSON(value.sustainabilityCriterion),
    };
}
exports.ProcessingStandardToJSON = ProcessingStandardToJSON;
