"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConfirmationCertificationPresentableToJSON = exports.ConfirmationCertificationPresentableFromJSONTyped = exports.ConfirmationCertificationPresentableFromJSON = exports.ConfirmationCertificationPresentableSubjectEnum = exports.ConfirmationCertificationPresentableStatusEnum = void 0;
const runtime_1 = require("../runtime");
const DocumentPresentable_1 = require("./DocumentPresentable");
const MaterialPresentable_1 = require("./MaterialPresentable");
/**
* @export
* @enum {string}
*/
var ConfirmationCertificationPresentableStatusEnum;
(function (ConfirmationCertificationPresentableStatusEnum) {
    ConfirmationCertificationPresentableStatusEnum["Pending"] = "PENDING";
    ConfirmationCertificationPresentableStatusEnum["Accepted"] = "ACCEPTED";
    ConfirmationCertificationPresentableStatusEnum["Refused"] = "REFUSED";
})(ConfirmationCertificationPresentableStatusEnum = exports.ConfirmationCertificationPresentableStatusEnum || (exports.ConfirmationCertificationPresentableStatusEnum = {})); /**
* @export
* @enum {string}
*/
var ConfirmationCertificationPresentableSubjectEnum;
(function (ConfirmationCertificationPresentableSubjectEnum) {
    ConfirmationCertificationPresentableSubjectEnum["Scope"] = "SCOPE";
    ConfirmationCertificationPresentableSubjectEnum["Transaction"] = "TRANSACTION";
    ConfirmationCertificationPresentableSubjectEnum["Material"] = "MATERIAL";
    ConfirmationCertificationPresentableSubjectEnum["Self"] = "SELF";
})(ConfirmationCertificationPresentableSubjectEnum = exports.ConfirmationCertificationPresentableSubjectEnum || (exports.ConfirmationCertificationPresentableSubjectEnum = {}));
function ConfirmationCertificationPresentableFromJSON(json) {
    return ConfirmationCertificationPresentableFromJSONTyped(json, false);
}
exports.ConfirmationCertificationPresentableFromJSON = ConfirmationCertificationPresentableFromJSON;
function ConfirmationCertificationPresentableFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
        'contractorReferenceNumber': !runtime_1.exists(json, 'contractorReferenceNumber') ? undefined : json['contractorReferenceNumber'],
        'consigneeReferenceNumber': !runtime_1.exists(json, 'consigneeReferenceNumber') ? undefined : json['consigneeReferenceNumber'],
        'contractorName': !runtime_1.exists(json, 'contractorName') ? undefined : json['contractorName'],
        'consigneeName': !runtime_1.exists(json, 'consigneeName') ? undefined : json['consigneeName'],
        'approverName': !runtime_1.exists(json, 'approverName') ? undefined : json['approverName'],
        'contractorEmail': !runtime_1.exists(json, 'contractorEmail') ? undefined : json['contractorEmail'],
        'consigneeEmail': !runtime_1.exists(json, 'consigneeEmail') ? undefined : json['consigneeEmail'],
        'validFrom': !runtime_1.exists(json, 'validFrom') ? undefined : (new Date(json['validFrom'])),
        'validUntil': !runtime_1.exists(json, 'validUntil') ? undefined : (new Date(json['validUntil'])),
        'contractorDate': !runtime_1.exists(json, 'contractorDate') ? undefined : (new Date(json['contractorDate'])),
        'document': !runtime_1.exists(json, 'document') ? undefined : DocumentPresentable_1.DocumentPresentableFromJSON(json['document']),
        'documentType': !runtime_1.exists(json, 'documentType') ? undefined : json['documentType'],
        'notes': !runtime_1.exists(json, 'notes') ? undefined : json['notes'],
        'status': !runtime_1.exists(json, 'status') ? undefined : json['status'],
        'processingStandardName': !runtime_1.exists(json, 'processingStandardName') ? undefined : json['processingStandardName'],
        'material': !runtime_1.exists(json, 'material') ? undefined : MaterialPresentable_1.MaterialPresentableFromJSON(json['material']),
        'shippingReferenceNumbers': !runtime_1.exists(json, 'shippingReferenceNumbers') ? undefined : json['shippingReferenceNumbers'],
        'assessmentType': !runtime_1.exists(json, 'assessmentType') ? undefined : json['assessmentType'],
        'productCategories': !runtime_1.exists(json, 'productCategories') ? undefined : json['productCategories'],
        'processTypes': !runtime_1.exists(json, 'processTypes') ? undefined : json['processTypes'],
        'subject': !runtime_1.exists(json, 'subject') ? undefined : json['subject'],
        'certificateReferenceNumber': !runtime_1.exists(json, 'certificateReferenceNumber') ? undefined : json['certificateReferenceNumber'],
        'certificatePageUrl': !runtime_1.exists(json, 'certificatePageUrl') ? undefined : json['certificatePageUrl'],
        'outputMaterials': !runtime_1.exists(json, 'outputMaterials') ? undefined : (json['outputMaterials'].map(MaterialPresentable_1.MaterialPresentableFromJSON)),
    };
}
exports.ConfirmationCertificationPresentableFromJSONTyped = ConfirmationCertificationPresentableFromJSONTyped;
function ConfirmationCertificationPresentableToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'contractorReferenceNumber': value.contractorReferenceNumber,
        'consigneeReferenceNumber': value.consigneeReferenceNumber,
        'contractorName': value.contractorName,
        'consigneeName': value.consigneeName,
        'approverName': value.approverName,
        'contractorEmail': value.contractorEmail,
        'consigneeEmail': value.consigneeEmail,
        'validFrom': value.validFrom === undefined ? undefined : (value.validFrom.toISOString()),
        'validUntil': value.validUntil === undefined ? undefined : (value.validUntil.toISOString()),
        'contractorDate': value.contractorDate === undefined ? undefined : (value.contractorDate.toISOString()),
        'document': DocumentPresentable_1.DocumentPresentableToJSON(value.document),
        'documentType': value.documentType,
        'notes': value.notes,
        'status': value.status,
        'processingStandardName': value.processingStandardName,
        'material': MaterialPresentable_1.MaterialPresentableToJSON(value.material),
        'shippingReferenceNumbers': value.shippingReferenceNumbers,
        'assessmentType': value.assessmentType,
        'productCategories': value.productCategories,
        'processTypes': value.processTypes,
        'subject': value.subject,
        'certificateReferenceNumber': value.certificateReferenceNumber,
        'certificatePageUrl': value.certificatePageUrl,
        'outputMaterials': value.outputMaterials === undefined ? undefined : (value.outputMaterials.map(MaterialPresentable_1.MaterialPresentableToJSON)),
    };
}
exports.ConfirmationCertificationPresentableToJSON = ConfirmationCertificationPresentableToJSON;
