"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransformationPlanPresentableToJSON = exports.TransformationPlanPresentableFromJSONTyped = exports.TransformationPlanPresentableFromJSON = void 0;
const runtime_1 = require("../runtime");
const MaterialPresentable_1 = require("./MaterialPresentable");
const PositionPresentable_1 = require("./PositionPresentable");
const ProcessTypePresentable_1 = require("./ProcessTypePresentable");
const ProcessingStandardPresentable_1 = require("./ProcessingStandardPresentable");
const ProductCategory_1 = require("./ProductCategory");
function TransformationPlanPresentableFromJSON(json) {
    return TransformationPlanPresentableFromJSONTyped(json, false);
}
exports.TransformationPlanPresentableFromJSON = TransformationPlanPresentableFromJSON;
function TransformationPlanPresentableFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'inputPositions': !runtime_1.exists(json, 'inputPositions') ? undefined : (json['inputPositions'].map(PositionPresentable_1.PositionPresentableFromJSON)),
        'outputMaterial': !runtime_1.exists(json, 'outputMaterial') ? undefined : MaterialPresentable_1.MaterialPresentableFromJSON(json['outputMaterial']),
        'processTypeList': !runtime_1.exists(json, 'processTypeList') ? undefined : (json['processTypeList'].map(ProcessTypePresentable_1.ProcessTypePresentableFromJSON)),
        'processingStandardList': !runtime_1.exists(json, 'processingStandardList') ? undefined : (json['processingStandardList'].map(ProcessingStandardPresentable_1.ProcessingStandardPresentableFromJSON)),
        'productCategory': !runtime_1.exists(json, 'productCategory') ? undefined : ProductCategory_1.ProductCategoryFromJSON(json['productCategory']),
        'creationDate': !runtime_1.exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
        'traceabilityLevel': !runtime_1.exists(json, 'traceabilityLevel') ? undefined : json['traceabilityLevel'],
        'transparencyLevel': !runtime_1.exists(json, 'transparencyLevel') ? undefined : json['transparencyLevel'],
        'validFrom': !runtime_1.exists(json, 'validFrom') ? undefined : (new Date(json['validFrom'])),
        'validUntil': !runtime_1.exists(json, 'validUntil') ? undefined : (new Date(json['validUntil'])),
        'notes': !runtime_1.exists(json, 'notes') ? undefined : json['notes'],
        'name': !runtime_1.exists(json, 'name') ? undefined : json['name'],
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
    };
}
exports.TransformationPlanPresentableFromJSONTyped = TransformationPlanPresentableFromJSONTyped;
function TransformationPlanPresentableToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'inputPositions': value.inputPositions === undefined ? undefined : (value.inputPositions.map(PositionPresentable_1.PositionPresentableToJSON)),
        'outputMaterial': MaterialPresentable_1.MaterialPresentableToJSON(value.outputMaterial),
        'processTypeList': value.processTypeList === undefined ? undefined : (value.processTypeList.map(ProcessTypePresentable_1.ProcessTypePresentableToJSON)),
        'processingStandardList': value.processingStandardList === undefined ? undefined : (value.processingStandardList.map(ProcessingStandardPresentable_1.ProcessingStandardPresentableToJSON)),
        'productCategory': ProductCategory_1.ProductCategoryToJSON(value.productCategory),
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
        'traceabilityLevel': value.traceabilityLevel,
        'transparencyLevel': value.transparencyLevel,
        'validFrom': value.validFrom === undefined ? undefined : (value.validFrom.toISOString()),
        'validUntil': value.validUntil === undefined ? undefined : (value.validUntil.toISOString()),
        'notes': value.notes,
        'name': value.name,
        'id': value.id,
    };
}
exports.TransformationPlanPresentableToJSON = TransformationPlanPresentableToJSON;
