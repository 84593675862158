"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TotalOnboardingRequestToJSON = exports.TotalOnboardingRequestFromJSONTyped = exports.TotalOnboardingRequestFromJSON = void 0;
const runtime_1 = require("../runtime");
const Country_1 = require("./Country");
const Role_1 = require("./Role");
function TotalOnboardingRequestFromJSON(json) {
    return TotalOnboardingRequestFromJSONTyped(json, false);
}
exports.TotalOnboardingRequestFromJSON = TotalOnboardingRequestFromJSON;
function TotalOnboardingRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'companyName': !runtime_1.exists(json, 'companyName') ? undefined : json['companyName'],
        'userFirstName': !runtime_1.exists(json, 'userFirstName') ? undefined : json['userFirstName'],
        'userLastName': !runtime_1.exists(json, 'userLastName') ? undefined : json['userLastName'],
        'userEmailAddress': !runtime_1.exists(json, 'userEmailAddress') ? undefined : json['userEmailAddress'],
        'userPhoneNumber': !runtime_1.exists(json, 'userPhoneNumber') ? undefined : json['userPhoneNumber'],
        'userState': !runtime_1.exists(json, 'userState') ? undefined : json['userState'],
        'userCity': !runtime_1.exists(json, 'userCity') ? undefined : json['userCity'],
        'userZipCode': !runtime_1.exists(json, 'userZipCode') ? undefined : json['userZipCode'],
        'userAddress1': !runtime_1.exists(json, 'userAddress1') ? undefined : json['userAddress1'],
        'username': !runtime_1.exists(json, 'username') ? undefined : json['username'],
        'password': !runtime_1.exists(json, 'password') ? undefined : json['password'],
        'companyCode': !runtime_1.exists(json, 'companyCode') ? undefined : json['companyCode'],
        'companyRole': !runtime_1.exists(json, 'companyRole') ? undefined : Role_1.RoleFromJSON(json['companyRole']),
        'companyWebsite': !runtime_1.exists(json, 'companyWebsite') ? undefined : json['companyWebsite'],
        'companyCountry': !runtime_1.exists(json, 'companyCountry') ? undefined : Country_1.CountryFromJSON(json['companyCountry']),
        'companyState': !runtime_1.exists(json, 'companyState') ? undefined : json['companyState'],
        'companyCity': !runtime_1.exists(json, 'companyCity') ? undefined : json['companyCity'],
        'companyAddress': !runtime_1.exists(json, 'companyAddress') ? undefined : json['companyAddress'],
        'companyLatitude': !runtime_1.exists(json, 'companyLatitude') ? undefined : json['companyLatitude'],
        'companyLongitude': !runtime_1.exists(json, 'companyLongitude') ? undefined : json['companyLongitude'],
    };
}
exports.TotalOnboardingRequestFromJSONTyped = TotalOnboardingRequestFromJSONTyped;
function TotalOnboardingRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'companyName': value.companyName,
        'userFirstName': value.userFirstName,
        'userLastName': value.userLastName,
        'userEmailAddress': value.userEmailAddress,
        'userPhoneNumber': value.userPhoneNumber,
        'userState': value.userState,
        'userCity': value.userCity,
        'userZipCode': value.userZipCode,
        'userAddress1': value.userAddress1,
        'username': value.username,
        'password': value.password,
        'companyCode': value.companyCode,
        'companyRole': Role_1.RoleToJSON(value.companyRole),
        'companyWebsite': value.companyWebsite,
        'companyCountry': Country_1.CountryToJSON(value.companyCountry),
        'companyState': value.companyState,
        'companyCity': value.companyCity,
        'companyAddress': value.companyAddress,
        'companyLatitude': value.companyLatitude,
        'companyLongitude': value.companyLongitude,
    };
}
exports.TotalOnboardingRequestToJSON = TotalOnboardingRequestToJSON;
