"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConfirmationTradePresentableToJSON = exports.ConfirmationTradePresentableFromJSONTyped = exports.ConfirmationTradePresentableFromJSON = exports.ConfirmationTradePresentableStatusEnum = void 0;
const runtime_1 = require("../runtime");
const DocumentPresentable_1 = require("./DocumentPresentable");
const PositionPresentable_1 = require("./PositionPresentable");
/**
* @export
* @enum {string}
*/
var ConfirmationTradePresentableStatusEnum;
(function (ConfirmationTradePresentableStatusEnum) {
    ConfirmationTradePresentableStatusEnum["Pending"] = "PENDING";
    ConfirmationTradePresentableStatusEnum["Accepted"] = "ACCEPTED";
    ConfirmationTradePresentableStatusEnum["Refused"] = "REFUSED";
})(ConfirmationTradePresentableStatusEnum = exports.ConfirmationTradePresentableStatusEnum || (exports.ConfirmationTradePresentableStatusEnum = {}));
function ConfirmationTradePresentableFromJSON(json) {
    return ConfirmationTradePresentableFromJSONTyped(json, false);
}
exports.ConfirmationTradePresentableFromJSON = ConfirmationTradePresentableFromJSON;
function ConfirmationTradePresentableFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
        'contractorReferenceNumber': !runtime_1.exists(json, 'contractorReferenceNumber') ? undefined : json['contractorReferenceNumber'],
        'consigneeReferenceNumber': !runtime_1.exists(json, 'consigneeReferenceNumber') ? undefined : json['consigneeReferenceNumber'],
        'contractorName': !runtime_1.exists(json, 'contractorName') ? undefined : json['contractorName'],
        'consigneeName': !runtime_1.exists(json, 'consigneeName') ? undefined : json['consigneeName'],
        'approverName': !runtime_1.exists(json, 'approverName') ? undefined : json['approverName'],
        'contractorEmail': !runtime_1.exists(json, 'contractorEmail') ? undefined : json['contractorEmail'],
        'consigneeEmail': !runtime_1.exists(json, 'consigneeEmail') ? undefined : json['consigneeEmail'],
        'validFrom': !runtime_1.exists(json, 'validFrom') ? undefined : (new Date(json['validFrom'])),
        'validUntil': !runtime_1.exists(json, 'validUntil') ? undefined : (new Date(json['validUntil'])),
        'contractorDate': !runtime_1.exists(json, 'contractorDate') ? undefined : (new Date(json['contractorDate'])),
        'document': !runtime_1.exists(json, 'document') ? undefined : DocumentPresentable_1.DocumentPresentableFromJSON(json['document']),
        'documentType': !runtime_1.exists(json, 'documentType') ? undefined : json['documentType'],
        'notes': !runtime_1.exists(json, 'notes') ? undefined : json['notes'],
        'status': !runtime_1.exists(json, 'status') ? undefined : json['status'],
        'processingStandardName': !runtime_1.exists(json, 'processingStandardName') ? undefined : json['processingStandardName'],
        'positions': !runtime_1.exists(json, 'positions') ? undefined : (json['positions'].map(PositionPresentable_1.PositionPresentableFromJSON)),
    };
}
exports.ConfirmationTradePresentableFromJSONTyped = ConfirmationTradePresentableFromJSONTyped;
function ConfirmationTradePresentableToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'contractorReferenceNumber': value.contractorReferenceNumber,
        'consigneeReferenceNumber': value.consigneeReferenceNumber,
        'contractorName': value.contractorName,
        'consigneeName': value.consigneeName,
        'approverName': value.approverName,
        'contractorEmail': value.contractorEmail,
        'consigneeEmail': value.consigneeEmail,
        'validFrom': value.validFrom === undefined ? undefined : (value.validFrom.toISOString()),
        'validUntil': value.validUntil === undefined ? undefined : (value.validUntil.toISOString()),
        'contractorDate': value.contractorDate === undefined ? undefined : (value.contractorDate.toISOString()),
        'document': DocumentPresentable_1.DocumentPresentableToJSON(value.document),
        'documentType': value.documentType,
        'notes': value.notes,
        'status': value.status,
        'processingStandardName': value.processingStandardName,
        'positions': value.positions === undefined ? undefined : (value.positions.map(PositionPresentable_1.PositionPresentableToJSON)),
    };
}
exports.ConfirmationTradePresentableToJSON = ConfirmationTradePresentableToJSON;
