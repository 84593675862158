"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserToJSON = exports.UserFromJSONTyped = exports.UserFromJSON = exports.UserRoleEnum = void 0;
const runtime_1 = require("../runtime");
const Company_1 = require("./Company");
const Country_1 = require("./Country");
/**
* @export
* @enum {string}
*/
var UserRoleEnum;
(function (UserRoleEnum) {
    UserRoleEnum["Admin"] = "ADMIN";
    UserRoleEnum["Operator"] = "OPERATOR";
    UserRoleEnum["Partner"] = "PARTNER";
})(UserRoleEnum = exports.UserRoleEnum || (exports.UserRoleEnum = {}));
function UserFromJSON(json) {
    return UserFromJSONTyped(json, false);
}
exports.UserFromJSON = UserFromJSON;
function UserFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'email': !runtime_1.exists(json, 'email') ? undefined : json['email'],
        'firstname': !runtime_1.exists(json, 'firstname') ? undefined : json['firstname'],
        'lastname': !runtime_1.exists(json, 'lastname') ? undefined : json['lastname'],
        'address1': !runtime_1.exists(json, 'address1') ? undefined : json['address1'],
        'address2': !runtime_1.exists(json, 'address2') ? undefined : json['address2'],
        'zip': !runtime_1.exists(json, 'zip') ? undefined : json['zip'],
        'city': !runtime_1.exists(json, 'city') ? undefined : json['city'],
        'country': !runtime_1.exists(json, 'country') ? undefined : Country_1.CountryFromJSON(json['country']),
        'phone': !runtime_1.exists(json, 'phone') ? undefined : json['phone'],
        'language': !runtime_1.exists(json, 'language') ? undefined : json['language'],
        'department': !runtime_1.exists(json, 'department') ? undefined : json['department'],
        'subDepartment': !runtime_1.exists(json, 'subDepartment') ? undefined : json['subDepartment'],
        'role': !runtime_1.exists(json, 'role') ? undefined : json['role'],
        'state': !runtime_1.exists(json, 'state') ? undefined : json['state'],
        'registrationDate': !runtime_1.exists(json, 'registrationDate') ? undefined : (new Date(json['registrationDate'])),
        'lastEditDate': !runtime_1.exists(json, 'lastEditDate') ? undefined : (new Date(json['lastEditDate'])),
        'company': !runtime_1.exists(json, 'company') ? undefined : Company_1.CompanyFromJSON(json['company']),
    };
}
exports.UserFromJSONTyped = UserFromJSONTyped;
function UserToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'email': value.email,
        'firstname': value.firstname,
        'lastname': value.lastname,
        'address1': value.address1,
        'address2': value.address2,
        'zip': value.zip,
        'city': value.city,
        'country': Country_1.CountryToJSON(value.country),
        'phone': value.phone,
        'language': value.language,
        'department': value.department,
        'subDepartment': value.subDepartment,
        'role': value.role,
        'state': value.state,
        'registrationDate': value.registrationDate === undefined ? undefined : (value.registrationDate.toISOString()),
        'lastEditDate': value.lastEditDate === undefined ? undefined : (value.lastEditDate.toISOString()),
        'company': Company_1.CompanyToJSON(value.company),
    };
}
exports.UserToJSON = UserToJSON;
