"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.InfoControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class InfoControllerApi extends runtime.BaseAPI {
    /**
     */
    getAllCountriesRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/info/countries`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.CountryFromJSON));
        });
    }
    /**
     */
    getAllCountries(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getAllCountriesRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    getCompanyRolesRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.invitedCompanyName === null || requestParameters.invitedCompanyName === undefined) {
                throw new runtime.RequiredError('invitedCompanyName', 'Required parameter requestParameters.invitedCompanyName was null or undefined when calling getCompanyRoles.');
            }
            const queryParameters = {};
            if (requestParameters.invitedCompanyName !== undefined) {
                queryParameters['invitedCompanyName'] = requestParameters.invitedCompanyName;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/info/roles`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.RoleFromJSON));
        });
    }
    /**
     */
    getCompanyRoles(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getCompanyRolesRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * getInfo
     */
    getInfoRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearerAuth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/info/info`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.UserFromJSON(jsonValue));
        });
    }
    /**
     * getInfo
     */
    getInfo(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getInfoRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    loginRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.username === null || requestParameters.username === undefined) {
                throw new runtime.RequiredError('username', 'Required parameter requestParameters.username was null or undefined when calling login.');
            }
            if (requestParameters.password === null || requestParameters.password === undefined) {
                throw new runtime.RequiredError('password', 'Required parameter requestParameters.password was null or undefined when calling login.');
            }
            const queryParameters = {};
            if (requestParameters.username !== undefined) {
                queryParameters['username'] = requestParameters.username;
            }
            if (requestParameters.password !== undefined) {
                queryParameters['password'] = requestParameters.password;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/info/authenticate`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.JwtTokenFromJSON(jsonValue));
        });
    }
    /**
     */
    login(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.loginRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    logoutRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/info/logout`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     */
    logout(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.logoutRaw(initOverrides);
        });
    }
}
exports.InfoControllerApi = InfoControllerApi;
