"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransformationPlanRequestToJSON = exports.TransformationPlanRequestFromJSONTyped = exports.TransformationPlanRequestFromJSON = void 0;
const runtime_1 = require("../runtime");
const TransformationPlanPositionRequest_1 = require("./TransformationPlanPositionRequest");
function TransformationPlanRequestFromJSON(json) {
    return TransformationPlanRequestFromJSONTyped(json, false);
}
exports.TransformationPlanRequestFromJSON = TransformationPlanRequestFromJSON;
function TransformationPlanRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !runtime_1.exists(json, 'name') ? undefined : json['name'],
        'positionRequestList': !runtime_1.exists(json, 'positionRequestList') ? undefined : (json['positionRequestList'].map(TransformationPlanPositionRequest_1.TransformationPlanPositionRequestFromJSON)),
        'processCodeList': !runtime_1.exists(json, 'processCodeList') ? undefined : json['processCodeList'],
        'processingStandardList': !runtime_1.exists(json, 'processingStandardList') ? undefined : json['processingStandardList'],
        'productCategoryCode': !runtime_1.exists(json, 'productCategoryCode') ? undefined : json['productCategoryCode'],
        'validFrom': !runtime_1.exists(json, 'validFrom') ? undefined : (new Date(json['validFrom'])),
        'validUntil': !runtime_1.exists(json, 'validUntil') ? undefined : (new Date(json['validUntil'])),
        'notes': !runtime_1.exists(json, 'notes') ? undefined : json['notes'],
        'traceabilityLevelName': !runtime_1.exists(json, 'traceabilityLevelName') ? undefined : json['traceabilityLevelName'],
        'transparencyLevelName': !runtime_1.exists(json, 'transparencyLevelName') ? undefined : json['transparencyLevelName'],
    };
}
exports.TransformationPlanRequestFromJSONTyped = TransformationPlanRequestFromJSONTyped;
function TransformationPlanRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'positionRequestList': value.positionRequestList === undefined ? undefined : (value.positionRequestList.map(TransformationPlanPositionRequest_1.TransformationPlanPositionRequestToJSON)),
        'processCodeList': value.processCodeList,
        'processingStandardList': value.processingStandardList,
        'productCategoryCode': value.productCategoryCode,
        'validFrom': value.validFrom === undefined ? undefined : (value.validFrom.toISOString()),
        'validUntil': value.validUntil === undefined ? undefined : (value.validUntil.toISOString()),
        'notes': value.notes,
        'traceabilityLevelName': value.traceabilityLevelName,
        'transparencyLevelName': value.transparencyLevelName,
    };
}
exports.TransformationPlanRequestToJSON = TransformationPlanRequestToJSON;
