"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SupplyChainMaterialPresentableToJSON = exports.SupplyChainMaterialPresentableFromJSONTyped = exports.SupplyChainMaterialPresentableFromJSON = void 0;
const runtime_1 = require("../runtime");
function SupplyChainMaterialPresentableFromJSON(json) {
    return SupplyChainMaterialPresentableFromJSONTyped(json, false);
}
exports.SupplyChainMaterialPresentableFromJSON = SupplyChainMaterialPresentableFromJSON;
function SupplyChainMaterialPresentableFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !runtime_1.exists(json, 'name') ? undefined : json['name'],
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
    };
}
exports.SupplyChainMaterialPresentableFromJSONTyped = SupplyChainMaterialPresentableFromJSONTyped;
function SupplyChainMaterialPresentableToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'id': value.id,
    };
}
exports.SupplyChainMaterialPresentableToJSON = SupplyChainMaterialPresentableToJSON;
