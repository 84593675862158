"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserRequestToJSON = exports.UserRequestFromJSONTyped = exports.UserRequestFromJSON = void 0;
const runtime_1 = require("../runtime");
function UserRequestFromJSON(json) {
    return UserRequestFromJSONTyped(json, false);
}
exports.UserRequestFromJSON = UserRequestFromJSON;
function UserRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'password': !runtime_1.exists(json, 'password') ? undefined : json['password'],
        'firstname': !runtime_1.exists(json, 'firstname') ? undefined : json['firstname'],
        'lastname': !runtime_1.exists(json, 'lastname') ? undefined : json['lastname'],
        'address1': !runtime_1.exists(json, 'address1') ? undefined : json['address1'],
        'address2': !runtime_1.exists(json, 'address2') ? undefined : json['address2'],
        'zip': !runtime_1.exists(json, 'zip') ? undefined : json['zip'],
        'city': !runtime_1.exists(json, 'city') ? undefined : json['city'],
        'phone': !runtime_1.exists(json, 'phone') ? undefined : json['phone'],
        'state': !runtime_1.exists(json, 'state') ? undefined : json['state'],
    };
}
exports.UserRequestFromJSONTyped = UserRequestFromJSONTyped;
function UserRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'password': value.password,
        'firstname': value.firstname,
        'lastname': value.lastname,
        'address1': value.address1,
        'address2': value.address2,
        'zip': value.zip,
        'city': value.city,
        'phone': value.phone,
        'state': value.state,
    };
}
exports.UserRequestToJSON = UserRequestToJSON;
