"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompanyControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class CompanyControllerApi extends runtime.BaseAPI {
    /**
     * Get company approvers
     */
    getCompanyApproversRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearerAuth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/companies/approvers`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.CompanyPresentableFromJSON));
        });
    }
    /**
     * Get company approvers
     */
    getCompanyApprovers(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getCompanyApproversRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    getCompanyEmailsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.companyName === null || requestParameters.companyName === undefined) {
                throw new runtime.RequiredError('companyName', 'Required parameter requestParameters.companyName was null or undefined when calling getCompanyEmails.');
            }
            const queryParameters = {};
            if (requestParameters.companyName !== undefined) {
                queryParameters['companyName'] = requestParameters.companyName;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/companies/emailAddresses`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    getCompanyEmails(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getCompanyEmailsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get company from its token code (obtained after invitation)
     */
    getCompanyFromTokenRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.token === null || requestParameters.token === undefined) {
                throw new runtime.RequiredError('token', 'Required parameter requestParameters.token was null or undefined when calling getCompanyFromToken.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/companies/{token}/onboarding`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.CompanyPresentableFromJSON(jsonValue));
        });
    }
    /**
     * Get company from its token code (obtained after invitation)
     */
    getCompanyFromToken(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getCompanyFromTokenRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get known company traders
     */
    getCompanyTradersRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearerAuth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/companies/traders`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.CompanyPresentableFromJSON));
        });
    }
    /**
     * Get known company traders
     */
    getCompanyTraders(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getCompanyTradersRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get wallet credentials of this facility
     */
    getFacilityCustodialWalletCredentialsRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearerAuth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/companies/custodialWalletCredentials`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.CustodialWalletCredentialsPresentableFromJSON(jsonValue));
        });
    }
    /**
     * Get wallet credentials of this facility
     */
    getFacilityCustodialWalletCredentials(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getFacilityCustodialWalletCredentialsRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    getPublicKeyByEthAddressRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.ethAddress === null || requestParameters.ethAddress === undefined) {
                throw new runtime.RequiredError('ethAddress', 'Required parameter requestParameters.ethAddress was null or undefined when calling getPublicKeyByEthAddress.');
            }
            const queryParameters = {};
            if (requestParameters.ethAddress !== undefined) {
                queryParameters['ethAddress'] = requestParameters.ethAddress;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/companies/publicKey`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.TextApiResponse(response);
        });
    }
    /**
     */
    getPublicKeyByEthAddress(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getPublicKeyByEthAddressRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get trader and certifier approvers for self certification (verified by second party)
     */
    getTradersAndCertifierApproversRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearerAuth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/companies/approvers/self_certification`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.CompanyPresentableFromJSON));
        });
    }
    /**
     * Get trader and certifier approvers for self certification (verified by second party)
     */
    getTradersAndCertifierApprovers(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getTradersAndCertifierApproversRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     * Finish the on-boarding phase of an invited company
     */
    postCompanyOnboardingRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.token === null || requestParameters.token === undefined) {
                throw new runtime.RequiredError('token', 'Required parameter requestParameters.token was null or undefined when calling postCompanyOnboarding.');
            }
            if (requestParameters.totalOnboardingRequest === null || requestParameters.totalOnboardingRequest === undefined) {
                throw new runtime.RequiredError('totalOnboardingRequest', 'Required parameter requestParameters.totalOnboardingRequest was null or undefined when calling postCompanyOnboarding.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/companies/{token}/onboarding`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.TotalOnboardingRequestToJSON(requestParameters.totalOnboardingRequest),
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Finish the on-boarding phase of an invited company
     */
    postCompanyOnboarding(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.postCompanyOnboardingRaw(requestParameters, initOverrides);
        });
    }
    /**
     * Invitation of a supplier company
     */
    postSupplierInvitationRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.totalOnboardingRequest === null || requestParameters.totalOnboardingRequest === undefined) {
                throw new runtime.RequiredError('totalOnboardingRequest', 'Required parameter requestParameters.totalOnboardingRequest was null or undefined when calling postSupplierInvitation.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/companies/supplier/invite`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.TotalOnboardingRequestToJSON(requestParameters.totalOnboardingRequest),
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Invitation of a supplier company
     */
    postSupplierInvitation(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.postSupplierInvitationRaw(requestParameters, initOverrides);
        });
    }
    /**
     * Set the wallet credentials for a facility
     */
    putFacilityCustodialWalletCredentialsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.custodialWalletCredentialsRequest === null || requestParameters.custodialWalletCredentialsRequest === undefined) {
                throw new runtime.RequiredError('custodialWalletCredentialsRequest', 'Required parameter requestParameters.custodialWalletCredentialsRequest was null or undefined when calling putFacilityCustodialWalletCredentials.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearerAuth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/companies/custodialWalletCredentials`,
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.CustodialWalletCredentialsRequestToJSON(requestParameters.custodialWalletCredentialsRequest),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.CustodialWalletCredentialsPresentableFromJSON(jsonValue));
        });
    }
    /**
     * Set the wallet credentials for a facility
     */
    putFacilityCustodialWalletCredentials(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.putFacilityCustodialWalletCredentialsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Update an existing company
     */
    updateCompanyRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.companyRequest === null || requestParameters.companyRequest === undefined) {
                throw new runtime.RequiredError('companyRequest', 'Required parameter requestParameters.companyRequest was null or undefined when calling updateCompany.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearerAuth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/companies/update`,
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.CompanyRequestToJSON(requestParameters.companyRequest),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.CompanyFromJSON(jsonValue));
        });
    }
    /**
     * Update an existing company
     */
    updateCompany(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.updateCompanyRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
exports.CompanyControllerApi = CompanyControllerApi;
