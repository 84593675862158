"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShippingTradeToJSON = exports.ShippingTradeFromJSONTyped = exports.ShippingTradeFromJSON = exports.ShippingTradeStatusEnum = void 0;
const runtime_1 = require("../runtime");
const CertificationTransaction_1 = require("./CertificationTransaction");
const Company_1 = require("./Company");
const Document_1 = require("./Document");
const DocumentType_1 = require("./DocumentType");
const OrderTrade_1 = require("./OrderTrade");
const ProcessingStandard_1 = require("./ProcessingStandard");
/**
* @export
* @enum {string}
*/
var ShippingTradeStatusEnum;
(function (ShippingTradeStatusEnum) {
    ShippingTradeStatusEnum["Pending"] = "PENDING";
    ShippingTradeStatusEnum["Accepted"] = "ACCEPTED";
    ShippingTradeStatusEnum["Refused"] = "REFUSED";
})(ShippingTradeStatusEnum = exports.ShippingTradeStatusEnum || (exports.ShippingTradeStatusEnum = {}));
function ShippingTradeFromJSON(json) {
    return ShippingTradeFromJSONTyped(json, false);
}
exports.ShippingTradeFromJSON = ShippingTradeFromJSON;
function ShippingTradeFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
        'contractorReferenceNumber': !runtime_1.exists(json, 'contractorReferenceNumber') ? undefined : json['contractorReferenceNumber'],
        'consigneeReferenceNumber': !runtime_1.exists(json, 'consigneeReferenceNumber') ? undefined : json['consigneeReferenceNumber'],
        'contractor': !runtime_1.exists(json, 'contractor') ? undefined : Company_1.CompanyFromJSON(json['contractor']),
        'consignee': !runtime_1.exists(json, 'consignee') ? undefined : Company_1.CompanyFromJSON(json['consignee']),
        'approver': !runtime_1.exists(json, 'approver') ? undefined : Company_1.CompanyFromJSON(json['approver']),
        'contractorEmail': !runtime_1.exists(json, 'contractorEmail') ? undefined : json['contractorEmail'],
        'consigneeEmail': !runtime_1.exists(json, 'consigneeEmail') ? undefined : json['consigneeEmail'],
        'validFrom': !runtime_1.exists(json, 'validFrom') ? undefined : (new Date(json['validFrom'])),
        'validUntil': !runtime_1.exists(json, 'validUntil') ? undefined : (new Date(json['validUntil'])),
        'document': !runtime_1.exists(json, 'document') ? undefined : Document_1.DocumentFromJSON(json['document']),
        'documentType': !runtime_1.exists(json, 'documentType') ? undefined : DocumentType_1.DocumentTypeFromJSON(json['documentType']),
        'notes': !runtime_1.exists(json, 'notes') ? undefined : json['notes'],
        'consigneeDate': !runtime_1.exists(json, 'consigneeDate') ? undefined : (new Date(json['consigneeDate'])),
        'contractorDate': !runtime_1.exists(json, 'contractorDate') ? undefined : (new Date(json['contractorDate'])),
        'status': !runtime_1.exists(json, 'status') ? undefined : json['status'],
        'processingStandard': !runtime_1.exists(json, 'processingStandard') ? undefined : ProcessingStandard_1.ProcessingStandardFromJSON(json['processingStandard']),
        'isSubcontracting': !runtime_1.exists(json, 'isSubcontracting') ? undefined : json['isSubcontracting'],
        'supplyChainVisibilityLevel': !runtime_1.exists(json, 'supplyChainVisibilityLevel') ? undefined : json['supplyChainVisibilityLevel'],
        'b2bLevel': !runtime_1.exists(json, 'b2bLevel') ? undefined : json['b2bLevel'],
        'documentApproval': !runtime_1.exists(json, 'documentApproval') ? undefined : json['documentApproval'],
        'processStandard': !runtime_1.exists(json, 'processStandard') ? undefined : json['processStandard'],
        'processAmount': !runtime_1.exists(json, 'processAmount') ? undefined : json['processAmount'],
        'toBeContacted': !runtime_1.exists(json, 'toBeContacted') ? undefined : json['toBeContacted'],
        'contactFirstName': !runtime_1.exists(json, 'contactFirstName') ? undefined : json['contactFirstName'],
        'contactLastName': !runtime_1.exists(json, 'contactLastName') ? undefined : json['contactLastName'],
        'contactPartnerName': !runtime_1.exists(json, 'contactPartnerName') ? undefined : json['contactPartnerName'],
        'contactEmail': !runtime_1.exists(json, 'contactEmail') ? undefined : json['contactEmail'],
        'certificationTransaction': !runtime_1.exists(json, 'certificationTransaction') ? undefined : CertificationTransaction_1.CertificationTransactionFromJSON(json['certificationTransaction']),
        'contractorParentReference': !runtime_1.exists(json, 'contractorParentReference') ? undefined : OrderTrade_1.OrderTradeFromJSON(json['contractorParentReference']),
        'consigneeParentReferenceNumber': !runtime_1.exists(json, 'consigneeParentReferenceNumber') ? undefined : json['consigneeParentReferenceNumber'],
    };
}
exports.ShippingTradeFromJSONTyped = ShippingTradeFromJSONTyped;
function ShippingTradeToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'contractorReferenceNumber': value.contractorReferenceNumber,
        'consigneeReferenceNumber': value.consigneeReferenceNumber,
        'contractor': Company_1.CompanyToJSON(value.contractor),
        'consignee': Company_1.CompanyToJSON(value.consignee),
        'approver': Company_1.CompanyToJSON(value.approver),
        'contractorEmail': value.contractorEmail,
        'consigneeEmail': value.consigneeEmail,
        'validFrom': value.validFrom === undefined ? undefined : (value.validFrom.toISOString()),
        'validUntil': value.validUntil === undefined ? undefined : (value.validUntil.toISOString()),
        'document': Document_1.DocumentToJSON(value.document),
        'documentType': DocumentType_1.DocumentTypeToJSON(value.documentType),
        'notes': value.notes,
        'consigneeDate': value.consigneeDate === undefined ? undefined : (value.consigneeDate.toISOString()),
        'contractorDate': value.contractorDate === undefined ? undefined : (value.contractorDate.toISOString()),
        'status': value.status,
        'processingStandard': ProcessingStandard_1.ProcessingStandardToJSON(value.processingStandard),
        'isSubcontracting': value.isSubcontracting,
        'supplyChainVisibilityLevel': value.supplyChainVisibilityLevel,
        'b2bLevel': value.b2bLevel,
        'documentApproval': value.documentApproval,
        'processStandard': value.processStandard,
        'processAmount': value.processAmount,
        'toBeContacted': value.toBeContacted,
        'contactFirstName': value.contactFirstName,
        'contactLastName': value.contactLastName,
        'contactPartnerName': value.contactPartnerName,
        'contactEmail': value.contactEmail,
        'certificationTransaction': CertificationTransaction_1.CertificationTransactionToJSON(value.certificationTransaction),
        'contractorParentReference': OrderTrade_1.OrderTradeToJSON(value.contractorParentReference),
        'consigneeParentReferenceNumber': value.consigneeParentReferenceNumber,
    };
}
exports.ShippingTradeToJSON = ShippingTradeToJSON;
