"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MaterialRequestToJSON = exports.MaterialRequestFromJSONTyped = exports.MaterialRequestFromJSON = void 0;
const runtime_1 = require("../runtime");
function MaterialRequestFromJSON(json) {
    return MaterialRequestFromJSONTyped(json, false);
}
exports.MaterialRequestFromJSON = MaterialRequestFromJSON;
function MaterialRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !runtime_1.exists(json, 'name') ? undefined : json['name'],
        'companyName': !runtime_1.exists(json, 'companyName') ? undefined : json['companyName'],
        'input': !runtime_1.exists(json, 'input') ? undefined : json['input'],
    };
}
exports.MaterialRequestFromJSONTyped = MaterialRequestFromJSONTyped;
function MaterialRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'companyName': value.companyName,
        'input': value.input,
    };
}
exports.MaterialRequestToJSON = MaterialRequestToJSON;
