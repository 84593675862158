"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SupplyChainTransformationPresentableToJSON = exports.SupplyChainTransformationPresentableFromJSONTyped = exports.SupplyChainTransformationPresentableFromJSON = void 0;
const runtime_1 = require("../runtime");
const SupplyChainCertificatePresentable_1 = require("./SupplyChainCertificatePresentable");
function SupplyChainTransformationPresentableFromJSON(json) {
    return SupplyChainTransformationPresentableFromJSONTyped(json, false);
}
exports.SupplyChainTransformationPresentableFromJSON = SupplyChainTransformationPresentableFromJSON;
function SupplyChainTransformationPresentableFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
        'name': !runtime_1.exists(json, 'name') ? undefined : json['name'],
        'productCategory': !runtime_1.exists(json, 'productCategory') ? undefined : json['productCategory'],
        'inputMaterialIds': !runtime_1.exists(json, 'input_material_ids') ? undefined : json['input_material_ids'],
        'outputMaterialIds': !runtime_1.exists(json, 'output_material_ids') ? undefined : json['output_material_ids'],
        'executorCompanyId': !runtime_1.exists(json, 'executor_company_id') ? undefined : json['executor_company_id'],
        'processesTypeNames': !runtime_1.exists(json, 'processesTypeNames') ? undefined : json['processesTypeNames'],
        'inputMaterialIdPercentageMap': !runtime_1.exists(json, 'input_material_id_percentage_map') ? undefined : json['input_material_id_percentage_map'],
        'processingStandards': !runtime_1.exists(json, 'processingStandards') ? undefined : json['processingStandards'],
        'certificates': !runtime_1.exists(json, 'certificates') ? undefined : (json['certificates'].map(SupplyChainCertificatePresentable_1.SupplyChainCertificatePresentableFromJSON)),
    };
}
exports.SupplyChainTransformationPresentableFromJSONTyped = SupplyChainTransformationPresentableFromJSONTyped;
function SupplyChainTransformationPresentableToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'name': value.name,
        'productCategory': value.productCategory,
        'input_material_ids': value.inputMaterialIds,
        'output_material_ids': value.outputMaterialIds,
        'executor_company_id': value.executorCompanyId,
        'processesTypeNames': value.processesTypeNames,
        'input_material_id_percentage_map': value.inputMaterialIdPercentageMap,
        'processingStandards': value.processingStandards,
        'certificates': value.certificates === undefined ? undefined : (value.certificates.map(SupplyChainCertificatePresentable_1.SupplyChainCertificatePresentableToJSON)),
    };
}
exports.SupplyChainTransformationPresentableToJSON = SupplyChainTransformationPresentableToJSON;
