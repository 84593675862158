"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompanyRequestToJSON = exports.CompanyRequestFromJSONTyped = exports.CompanyRequestFromJSON = void 0;
const runtime_1 = require("../runtime");
function CompanyRequestFromJSON(json) {
    return CompanyRequestFromJSONTyped(json, false);
}
exports.CompanyRequestFromJSON = CompanyRequestFromJSON;
function CompanyRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !runtime_1.exists(json, 'name') ? undefined : json['name'],
        'code': !runtime_1.exists(json, 'code') ? undefined : json['code'],
        'state': !runtime_1.exists(json, 'state') ? undefined : json['state'],
        'city': !runtime_1.exists(json, 'city') ? undefined : json['city'],
        'zip': !runtime_1.exists(json, 'zip') ? undefined : json['zip'],
        'address1': !runtime_1.exists(json, 'address1') ? undefined : json['address1'],
        'address2': !runtime_1.exists(json, 'address2') ? undefined : json['address2'],
        'latitude': !runtime_1.exists(json, 'latitude') ? undefined : json['latitude'],
        'longitude': !runtime_1.exists(json, 'longitude') ? undefined : json['longitude'],
        'website': !runtime_1.exists(json, 'website') ? undefined : json['website'],
        'country': !runtime_1.exists(json, 'country') ? undefined : json['country'],
    };
}
exports.CompanyRequestFromJSONTyped = CompanyRequestFromJSONTyped;
function CompanyRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'code': value.code,
        'state': value.state,
        'city': value.city,
        'zip': value.zip,
        'address1': value.address1,
        'address2': value.address2,
        'latitude': value.latitude,
        'longitude': value.longitude,
        'website': value.website,
        'country': value.country,
    };
}
exports.CompanyRequestToJSON = CompanyRequestToJSON;
