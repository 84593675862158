"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShippingPositionToJSON = exports.ShippingPositionFromJSONTyped = exports.ShippingPositionFromJSON = void 0;
const runtime_1 = require("../runtime");
const Material_1 = require("./Material");
const OrderPosition_1 = require("./OrderPosition");
const ShippingTrade_1 = require("./ShippingTrade");
const Unit_1 = require("./Unit");
function ShippingPositionFromJSON(json) {
    return ShippingPositionFromJSONTyped(json, false);
}
exports.ShippingPositionFromJSON = ShippingPositionFromJSON;
function ShippingPositionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
        'contractorMaterial': !runtime_1.exists(json, 'contractorMaterial') ? undefined : Material_1.MaterialFromJSON(json['contractorMaterial']),
        'consigneeMaterial': !runtime_1.exists(json, 'consigneeMaterial') ? undefined : Material_1.MaterialFromJSON(json['consigneeMaterial']),
        'weight': !runtime_1.exists(json, 'weight') ? undefined : json['weight'],
        'quantity': !runtime_1.exists(json, 'quantity') ? undefined : json['quantity'],
        'externalDescription': !runtime_1.exists(json, 'externalDescription') ? undefined : json['externalDescription'],
        'unit': !runtime_1.exists(json, 'unit') ? undefined : Unit_1.UnitFromJSON(json['unit']),
        'shippingTrade': !runtime_1.exists(json, 'shippingTrade') ? undefined : ShippingTrade_1.ShippingTradeFromJSON(json['shippingTrade']),
        'orderPosition': !runtime_1.exists(json, 'orderPosition') ? undefined : OrderPosition_1.OrderPositionFromJSON(json['orderPosition']),
    };
}
exports.ShippingPositionFromJSONTyped = ShippingPositionFromJSONTyped;
function ShippingPositionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'contractorMaterial': Material_1.MaterialToJSON(value.contractorMaterial),
        'consigneeMaterial': Material_1.MaterialToJSON(value.consigneeMaterial),
        'weight': value.weight,
        'quantity': value.quantity,
        'externalDescription': value.externalDescription,
        'unit': Unit_1.UnitToJSON(value.unit),
        'shippingTrade': ShippingTrade_1.ShippingTradeToJSON(value.shippingTrade),
        'orderPosition': OrderPosition_1.OrderPositionToJSON(value.orderPosition),
    };
}
exports.ShippingPositionToJSON = ShippingPositionToJSON;
