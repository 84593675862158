"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SupplyChainInfoPresentableToJSON = exports.SupplyChainInfoPresentableFromJSONTyped = exports.SupplyChainInfoPresentableFromJSON = void 0;
const runtime_1 = require("../runtime");
const SupplyChainCompanyInfoPresentable_1 = require("./SupplyChainCompanyInfoPresentable");
const SupplyChainMaterialPresentable_1 = require("./SupplyChainMaterialPresentable");
const SupplyChainTradePresentable_1 = require("./SupplyChainTradePresentable");
const SupplyChainTransformationPresentable_1 = require("./SupplyChainTransformationPresentable");
function SupplyChainInfoPresentableFromJSON(json) {
    return SupplyChainInfoPresentableFromJSONTyped(json, false);
}
exports.SupplyChainInfoPresentableFromJSON = SupplyChainInfoPresentableFromJSON;
function SupplyChainInfoPresentableFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'companiesInfo': !runtime_1.exists(json, 'companiesInfo') ? undefined : (json['companiesInfo'].map(SupplyChainCompanyInfoPresentable_1.SupplyChainCompanyInfoPresentableFromJSON)),
        'materials': !runtime_1.exists(json, 'materials') ? undefined : (json['materials'].map(SupplyChainMaterialPresentable_1.SupplyChainMaterialPresentableFromJSON)),
        'trades': !runtime_1.exists(json, 'trades') ? undefined : (json['trades'].map(SupplyChainTradePresentable_1.SupplyChainTradePresentableFromJSON)),
        'transformations': !runtime_1.exists(json, 'transformations') ? undefined : (json['transformations'].map(SupplyChainTransformationPresentable_1.SupplyChainTransformationPresentableFromJSON)),
    };
}
exports.SupplyChainInfoPresentableFromJSONTyped = SupplyChainInfoPresentableFromJSONTyped;
function SupplyChainInfoPresentableToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'companiesInfo': value.companiesInfo === undefined ? undefined : (value.companiesInfo.map(SupplyChainCompanyInfoPresentable_1.SupplyChainCompanyInfoPresentableToJSON)),
        'materials': value.materials === undefined ? undefined : (value.materials.map(SupplyChainMaterialPresentable_1.SupplyChainMaterialPresentableToJSON)),
        'trades': value.trades === undefined ? undefined : (value.trades.map(SupplyChainTradePresentable_1.SupplyChainTradePresentableToJSON)),
        'transformations': value.transformations === undefined ? undefined : (value.transformations.map(SupplyChainTransformationPresentable_1.SupplyChainTransformationPresentableToJSON)),
    };
}
exports.SupplyChainInfoPresentableToJSON = SupplyChainInfoPresentableToJSON;
