"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MaterialPresentableToJSON = exports.MaterialPresentableFromJSONTyped = exports.MaterialPresentableFromJSON = void 0;
const runtime_1 = require("../runtime");
function MaterialPresentableFromJSON(json) {
    return MaterialPresentableFromJSONTyped(json, false);
}
exports.MaterialPresentableFromJSON = MaterialPresentableFromJSON;
function MaterialPresentableFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
        'name': !runtime_1.exists(json, 'name') ? undefined : json['name'],
        'isInput': !runtime_1.exists(json, 'isInput') ? undefined : json['isInput'],
    };
}
exports.MaterialPresentableFromJSONTyped = MaterialPresentableFromJSONTyped;
function MaterialPresentableToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'name': value.name,
        'isInput': value.isInput,
    };
}
exports.MaterialPresentableToJSON = MaterialPresentableToJSON;
