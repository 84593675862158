"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProcessingStandardPresentableToJSON = exports.ProcessingStandardPresentableFromJSONTyped = exports.ProcessingStandardPresentableFromJSON = void 0;
const runtime_1 = require("../runtime");
function ProcessingStandardPresentableFromJSON(json) {
    return ProcessingStandardPresentableFromJSONTyped(json, false);
}
exports.ProcessingStandardPresentableFromJSON = ProcessingStandardPresentableFromJSON;
function ProcessingStandardPresentableFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !runtime_1.exists(json, 'name') ? undefined : json['name'],
    };
}
exports.ProcessingStandardPresentableFromJSONTyped = ProcessingStandardPresentableFromJSONTyped;
function ProcessingStandardPresentableToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
    };
}
exports.ProcessingStandardPresentableToJSON = ProcessingStandardPresentableToJSON;
