"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MaterialToJSON = exports.MaterialFromJSONTyped = exports.MaterialFromJSON = void 0;
const runtime_1 = require("../runtime");
const Company_1 = require("./Company");
const ProductCategory_1 = require("./ProductCategory");
function MaterialFromJSON(json) {
    return MaterialFromJSONTyped(json, false);
}
exports.MaterialFromJSON = MaterialFromJSON;
function MaterialFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
        'name': !runtime_1.exists(json, 'name') ? undefined : json['name'],
        'company': !runtime_1.exists(json, 'company') ? undefined : Company_1.CompanyFromJSON(json['company']),
        'productCategory': !runtime_1.exists(json, 'productCategory') ? undefined : ProductCategory_1.ProductCategoryFromJSON(json['productCategory']),
        'input': !runtime_1.exists(json, 'input') ? undefined : json['input'],
    };
}
exports.MaterialFromJSONTyped = MaterialFromJSONTyped;
function MaterialToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'name': value.name,
        'company': Company_1.CompanyToJSON(value.company),
        'productCategory': ProductCategory_1.ProductCategoryToJSON(value.productCategory),
        'input': value.input,
    };
}
exports.MaterialToJSON = MaterialToJSON;
