"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContractRequestToJSON = exports.ContractRequestFromJSONTyped = exports.ContractRequestFromJSON = void 0;
const runtime_1 = require("../runtime");
const ContractPosition_1 = require("./ContractPosition");
const DocumentRequest_1 = require("./DocumentRequest");
function ContractRequestFromJSON(json) {
    return ContractRequestFromJSONTyped(json, false);
}
exports.ContractRequestFromJSON = ContractRequestFromJSON;
function ContractRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'consigneeCompanyName': !runtime_1.exists(json, 'consigneeCompanyName') ? undefined : json['consigneeCompanyName'],
        'consigneeEmail': !runtime_1.exists(json, 'consigneeEmail') ? undefined : json['consigneeEmail'],
        'validFrom': !runtime_1.exists(json, 'validFrom') ? undefined : (new Date(json['validFrom'])),
        'validUntil': !runtime_1.exists(json, 'validUntil') ? undefined : (new Date(json['validUntil'])),
        'documentTypeCode': !runtime_1.exists(json, 'documentTypeCode') ? undefined : json['documentTypeCode'],
        'documentUpload': !runtime_1.exists(json, 'documentUpload') ? undefined : DocumentRequest_1.DocumentRequestFromJSON(json['documentUpload']),
        'processingStandardName': !runtime_1.exists(json, 'processingStandardName') ? undefined : json['processingStandardName'],
        'notes': !runtime_1.exists(json, 'notes') ? undefined : json['notes'],
        'contractorReferenceNumber': !runtime_1.exists(json, 'contractorReferenceNumber') ? undefined : json['contractorReferenceNumber'],
        'positions': !runtime_1.exists(json, 'positions') ? undefined : (json['positions'].map(ContractPosition_1.ContractPositionFromJSON)),
        'invitation': !runtime_1.exists(json, 'invitation') ? undefined : json['invitation'],
    };
}
exports.ContractRequestFromJSONTyped = ContractRequestFromJSONTyped;
function ContractRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'consigneeCompanyName': value.consigneeCompanyName,
        'consigneeEmail': value.consigneeEmail,
        'validFrom': value.validFrom === undefined ? undefined : (value.validFrom.toISOString()),
        'validUntil': value.validUntil === undefined ? undefined : (value.validUntil.toISOString()),
        'documentTypeCode': value.documentTypeCode,
        'documentUpload': DocumentRequest_1.DocumentRequestToJSON(value.documentUpload),
        'processingStandardName': value.processingStandardName,
        'notes': value.notes,
        'contractorReferenceNumber': value.contractorReferenceNumber,
        'positions': value.positions === undefined ? undefined : (value.positions.map(ContractPosition_1.ContractPositionToJSON)),
        'invitation': value.invitation,
    };
}
exports.ContractRequestToJSON = ContractRequestToJSON;
