"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CertificationControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class CertificationControllerApi extends runtime.BaseAPI {
    /**
     * Create a certification
     */
    createCertificationRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.certificationRequest === null || requestParameters.certificationRequest === undefined) {
                throw new runtime.RequiredError('certificationRequest', 'Required parameter requestParameters.certificationRequest was null or undefined when calling createCertification.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearerAuth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/certifications`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.CertificationRequestToJSON(requestParameters.certificationRequest),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ConfirmationCertificationPresentableFromJSON(jsonValue));
        });
    }
    /**
     * Create a certification
     */
    createCertification(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.createCertificationRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    getAllProductCategoriesRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/certifications/productCategories`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.ProductCategoryFromJSON));
        });
    }
    /**
     */
    getAllProductCategories(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getAllProductCategoriesRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    getAssessmentTypesRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters.type !== undefined) {
                queryParameters['type'] = requestParameters.type;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/certifications/assessments/types`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.AssessmentTypePresentableFromJSON));
        });
    }
    /**
     */
    getAssessmentTypes(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getAssessmentTypesRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    getCertificationRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getCertification.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/certifications/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ConfirmationCertificationPresentableFromJSON(jsonValue));
        });
    }
    /**
     */
    getCertification(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getCertificationRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    getCertificationProcessingStandardsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters.type !== undefined) {
                queryParameters['type'] = requestParameters.type;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/certifications/processingStandards`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.ProcessingStandardPresentableFromJSON));
        });
    }
    /**
     */
    getCertificationProcessingStandards(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getCertificationProcessingStandardsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get Certification Ids By Transaction Id and Type
     */
    getCertificationsByTransactionIdRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.transactionType === null || requestParameters.transactionType === undefined) {
                throw new runtime.RequiredError('transactionType', 'Required parameter requestParameters.transactionType was null or undefined when calling getCertificationsByTransactionId.');
            }
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getCertificationsByTransactionId.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearerAuth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/certifications/{transactionType}/{id}`.replace(`{${"transactionType"}}`, encodeURIComponent(String(requestParameters.transactionType))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.ConfirmationCertificationPresentableFromJSON));
        });
    }
    /**
     * Get Certification Ids By Transaction Id and Type
     */
    getCertificationsByTransactionId(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getCertificationsByTransactionIdRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    getMyCertificationsRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/certifications`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.TableCertificationPresentableFromJSON));
        });
    }
    /**
     */
    getMyCertifications(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getMyCertificationsRaw(initOverrides);
            return yield response.value();
        });
    }
}
exports.CertificationControllerApi = CertificationControllerApi;
