"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class TransactionControllerApi extends runtime.BaseAPI {
    /**
     * Confirm a transaction
     */
    confirmTransactionRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling confirmTransaction.');
            }
            if (requestParameters.type === null || requestParameters.type === undefined) {
                throw new runtime.RequiredError('type', 'Required parameter requestParameters.type was null or undefined when calling confirmTransaction.');
            }
            if (requestParameters.confirmationTransactionRequest === null || requestParameters.confirmationTransactionRequest === undefined) {
                throw new runtime.RequiredError('confirmationTransactionRequest', 'Required parameter requestParameters.confirmationTransactionRequest was null or undefined when calling confirmTransaction.');
            }
            const queryParameters = {};
            if (requestParameters.type !== undefined) {
                queryParameters['type'] = requestParameters.type;
            }
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearerAuth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/transactions/{id}/confirmation`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.ConfirmationTransactionRequestToJSON(requestParameters.confirmationTransactionRequest),
            }, initOverrides);
            return new runtime.TextApiResponse(response);
        });
    }
    /**
     * Confirm a transaction
     */
    confirmTransaction(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.confirmTransactionRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
exports.TransactionControllerApi = TransactionControllerApi;
