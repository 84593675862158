"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustodialWalletCredentialsRequestToJSON = exports.CustodialWalletCredentialsRequestFromJSONTyped = exports.CustodialWalletCredentialsRequestFromJSON = void 0;
const runtime_1 = require("../runtime");
function CustodialWalletCredentialsRequestFromJSON(json) {
    return CustodialWalletCredentialsRequestFromJSONTyped(json, false);
}
exports.CustodialWalletCredentialsRequestFromJSON = CustodialWalletCredentialsRequestFromJSON;
function CustodialWalletCredentialsRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'privateKey': !runtime_1.exists(json, 'privateKey') ? undefined : json['privateKey'],
        'publicKey': !runtime_1.exists(json, 'publicKey') ? undefined : json['publicKey'],
    };
}
exports.CustodialWalletCredentialsRequestFromJSONTyped = CustodialWalletCredentialsRequestFromJSONTyped;
function CustodialWalletCredentialsRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'privateKey': value.privateKey,
        'publicKey': value.publicKey,
    };
}
exports.CustodialWalletCredentialsRequestToJSON = CustodialWalletCredentialsRequestToJSON;
