"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompanyPresentableToJSON = exports.CompanyPresentableFromJSONTyped = exports.CompanyPresentableFromJSON = void 0;
const runtime_1 = require("../runtime");
const Role_1 = require("./Role");
function CompanyPresentableFromJSON(json) {
    return CompanyPresentableFromJSONTyped(json, false);
}
exports.CompanyPresentableFromJSON = CompanyPresentableFromJSON;
function CompanyPresentableFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'role': !runtime_1.exists(json, 'role') ? undefined : Role_1.RoleFromJSON(json['role']),
        'ethAddress': !runtime_1.exists(json, 'ethAddress') ? undefined : json['ethAddress'],
        'companyName': !runtime_1.exists(json, 'companyName') ? undefined : json['companyName'],
        'address': !runtime_1.exists(json, 'address') ? undefined : json['address'],
    };
}
exports.CompanyPresentableFromJSONTyped = CompanyPresentableFromJSONTyped;
function CompanyPresentableToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'role': Role_1.RoleToJSON(value.role),
        'ethAddress': value.ethAddress,
        'companyName': value.companyName,
        'address': value.address,
    };
}
exports.CompanyPresentableToJSON = CompanyPresentableToJSON;
