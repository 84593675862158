"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SupplyChainCertificatePresentableToJSON = exports.SupplyChainCertificatePresentableFromJSONTyped = exports.SupplyChainCertificatePresentableFromJSON = exports.SupplyChainCertificatePresentableStatusEnum = exports.SupplyChainCertificatePresentableSubjectEnum = void 0;
const runtime_1 = require("../runtime");
/**
* @export
* @enum {string}
*/
var SupplyChainCertificatePresentableSubjectEnum;
(function (SupplyChainCertificatePresentableSubjectEnum) {
    SupplyChainCertificatePresentableSubjectEnum["Scope"] = "SCOPE";
    SupplyChainCertificatePresentableSubjectEnum["Transaction"] = "TRANSACTION";
    SupplyChainCertificatePresentableSubjectEnum["Material"] = "MATERIAL";
    SupplyChainCertificatePresentableSubjectEnum["Self"] = "SELF";
})(SupplyChainCertificatePresentableSubjectEnum = exports.SupplyChainCertificatePresentableSubjectEnum || (exports.SupplyChainCertificatePresentableSubjectEnum = {})); /**
* @export
* @enum {string}
*/
var SupplyChainCertificatePresentableStatusEnum;
(function (SupplyChainCertificatePresentableStatusEnum) {
    SupplyChainCertificatePresentableStatusEnum["Pending"] = "PENDING";
    SupplyChainCertificatePresentableStatusEnum["Accepted"] = "ACCEPTED";
    SupplyChainCertificatePresentableStatusEnum["Refused"] = "REFUSED";
})(SupplyChainCertificatePresentableStatusEnum = exports.SupplyChainCertificatePresentableStatusEnum || (exports.SupplyChainCertificatePresentableStatusEnum = {}));
function SupplyChainCertificatePresentableFromJSON(json) {
    return SupplyChainCertificatePresentableFromJSONTyped(json, false);
}
exports.SupplyChainCertificatePresentableFromJSON = SupplyChainCertificatePresentableFromJSON;
function SupplyChainCertificatePresentableFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'certificateId': !runtime_1.exists(json, 'certificateId') ? undefined : json['certificateId'],
        'certifierName': !runtime_1.exists(json, 'certifierName') ? undefined : json['certifierName'],
        'processingStandardName': !runtime_1.exists(json, 'processingStandardName') ? undefined : json['processingStandardName'],
        'processingStandardLogoPath': !runtime_1.exists(json, 'processingStandardLogoPath') ? undefined : json['processingStandardLogoPath'],
        'processingStandardSiteUrl': !runtime_1.exists(json, 'processingStandardSiteUrl') ? undefined : json['processingStandardSiteUrl'],
        'processTypes': !runtime_1.exists(json, 'processTypes') ? undefined : json['processTypes'],
        'certificateTypeName': !runtime_1.exists(json, 'certificateTypeName') ? undefined : json['certificateTypeName'],
        'assessmentTypeName': !runtime_1.exists(json, 'assessmentTypeName') ? undefined : json['assessmentTypeName'],
        'creationDate': !runtime_1.exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
        'validUntil': !runtime_1.exists(json, 'validUntil') ? undefined : (new Date(json['validUntil'])),
        'validFrom': !runtime_1.exists(json, 'validFrom') ? undefined : (new Date(json['validFrom'])),
        'certificatePageURL': !runtime_1.exists(json, 'certificatePageURL') ? undefined : json['certificatePageURL'],
        'documentId': !runtime_1.exists(json, 'documentId') ? undefined : json['documentId'],
        'documentFileName': !runtime_1.exists(json, 'documentFileName') ? undefined : json['documentFileName'],
        'subject': !runtime_1.exists(json, 'subject') ? undefined : json['subject'],
        'status': !runtime_1.exists(json, 'status') ? undefined : json['status'],
    };
}
exports.SupplyChainCertificatePresentableFromJSONTyped = SupplyChainCertificatePresentableFromJSONTyped;
function SupplyChainCertificatePresentableToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'certificateId': value.certificateId,
        'certifierName': value.certifierName,
        'processingStandardName': value.processingStandardName,
        'processingStandardLogoPath': value.processingStandardLogoPath,
        'processingStandardSiteUrl': value.processingStandardSiteUrl,
        'processTypes': value.processTypes,
        'certificateTypeName': value.certificateTypeName,
        'assessmentTypeName': value.assessmentTypeName,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
        'validUntil': value.validUntil === undefined ? undefined : (value.validUntil.toISOString()),
        'validFrom': value.validFrom === undefined ? undefined : (value.validFrom.toISOString()),
        'certificatePageURL': value.certificatePageURL,
        'documentId': value.documentId,
        'documentFileName': value.documentFileName,
        'subject': value.subject,
        'status': value.status,
    };
}
exports.SupplyChainCertificatePresentableToJSON = SupplyChainCertificatePresentableToJSON;
