"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompanyToJSON = exports.CompanyFromJSONTyped = exports.CompanyFromJSON = void 0;
const runtime_1 = require("../runtime");
const CompanyIndustry_1 = require("./CompanyIndustry");
const Country_1 = require("./Country");
const CustodialWalletCredentials_1 = require("./CustodialWalletCredentials");
const Role_1 = require("./Role");
function CompanyFromJSON(json) {
    return CompanyFromJSONTyped(json, false);
}
exports.CompanyFromJSON = CompanyFromJSON;
function CompanyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'ethAddress': !runtime_1.exists(json, 'ethAddress') ? undefined : json['ethAddress'],
        'companyName': !runtime_1.exists(json, 'companyName') ? undefined : json['companyName'],
        'companyCode': !runtime_1.exists(json, 'companyCode') ? undefined : json['companyCode'],
        'partnerType': !runtime_1.exists(json, 'partnerType') ? undefined : Role_1.RoleFromJSON(json['partnerType']),
        'visibilityLevel': !runtime_1.exists(json, 'visibilityLevel') ? undefined : json['visibilityLevel'],
        'companyDivision': !runtime_1.exists(json, 'companyDivision') ? undefined : json['companyDivision'],
        'address1': !runtime_1.exists(json, 'address1') ? undefined : json['address1'],
        'address2': !runtime_1.exists(json, 'address2') ? undefined : json['address2'],
        'latitude': !runtime_1.exists(json, 'latitude') ? undefined : json['latitude'],
        'longitude': !runtime_1.exists(json, 'longitude') ? undefined : json['longitude'],
        'website': !runtime_1.exists(json, 'website') ? undefined : json['website'],
        'logo': !runtime_1.exists(json, 'logo') ? undefined : json['logo'],
        'companyEmail': !runtime_1.exists(json, 'companyEmail') ? undefined : json['companyEmail'],
        'companyPhone': !runtime_1.exists(json, 'companyPhone') ? undefined : json['companyPhone'],
        'zip': !runtime_1.exists(json, 'zip') ? undefined : json['zip'],
        'city': !runtime_1.exists(json, 'city') ? undefined : json['city'],
        'country': !runtime_1.exists(json, 'country') ? undefined : Country_1.CountryFromJSON(json['country']),
        'state': !runtime_1.exists(json, 'state') ? undefined : json['state'],
        'registrationDate': !runtime_1.exists(json, 'registrationDate') ? undefined : (new Date(json['registrationDate'])),
        'lastEditDate': !runtime_1.exists(json, 'lastEditDate') ? undefined : (new Date(json['lastEditDate'])),
        'companyHead': !runtime_1.exists(json, 'companyHead') ? undefined : CompanyFromJSON(json['companyHead']),
        'custodialWalletCredentials': !runtime_1.exists(json, 'custodialWalletCredentials') ? undefined : CustodialWalletCredentials_1.CustodialWalletCredentialsFromJSON(json['custodialWalletCredentials']),
        'companyIndustry': !runtime_1.exists(json, 'companyIndustry') ? undefined : CompanyIndustry_1.CompanyIndustryFromJSON(json['companyIndustry']),
        'gs1Id': !runtime_1.exists(json, 'gs1Id') ? undefined : json['gs1Id'],
    };
}
exports.CompanyFromJSONTyped = CompanyFromJSONTyped;
function CompanyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'ethAddress': value.ethAddress,
        'companyName': value.companyName,
        'companyCode': value.companyCode,
        'partnerType': Role_1.RoleToJSON(value.partnerType),
        'visibilityLevel': value.visibilityLevel,
        'companyDivision': value.companyDivision,
        'address1': value.address1,
        'address2': value.address2,
        'latitude': value.latitude,
        'longitude': value.longitude,
        'website': value.website,
        'logo': value.logo,
        'companyEmail': value.companyEmail,
        'companyPhone': value.companyPhone,
        'zip': value.zip,
        'city': value.city,
        'country': Country_1.CountryToJSON(value.country),
        'state': value.state,
        'registrationDate': value.registrationDate === undefined ? undefined : (value.registrationDate.toISOString()),
        'lastEditDate': value.lastEditDate === undefined ? undefined : (value.lastEditDate.toISOString()),
        'companyHead': CompanyToJSON(value.companyHead),
        'custodialWalletCredentials': CustodialWalletCredentials_1.CustodialWalletCredentialsToJSON(value.custodialWalletCredentials),
        'companyIndustry': CompanyIndustry_1.CompanyIndustryToJSON(value.companyIndustry),
        'gs1Id': value.gs1Id,
    };
}
exports.CompanyToJSON = CompanyToJSON;
