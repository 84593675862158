"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransformationPlanControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class TransformationPlanControllerApi extends runtime.BaseAPI {
    /**
     * Create a new transformation plan
     */
    createTransformationPlanRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.transformationPlanRequest === null || requestParameters.transformationPlanRequest === undefined) {
                throw new runtime.RequiredError('transformationPlanRequest', 'Required parameter requestParameters.transformationPlanRequest was null or undefined when calling createTransformationPlan.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearerAuth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/transformationPlans/create`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.TransformationPlanRequestToJSON(requestParameters.transformationPlanRequest),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.TransformationPlanPresentableFromJSON(jsonValue));
        });
    }
    /**
     * Create a new transformation plan
     */
    createTransformationPlan(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.createTransformationPlanRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    deleteTransformationPlanRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling deleteTransformationPlan.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/transformationPlans/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     */
    deleteTransformationPlan(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.deleteTransformationPlanRaw(requestParameters, initOverrides);
        });
    }
    /**
     */
    getAllMyTransformationPlansRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/transformationPlans`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.TransformationPlanPresentableFromJSON));
        });
    }
    /**
     */
    getAllMyTransformationPlans(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getAllMyTransformationPlansRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    getTransformationPlanRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getTransformationPlan.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/transformationPlans/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.TransformationPlanPresentableFromJSON(jsonValue));
        });
    }
    /**
     */
    getTransformationPlan(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getTransformationPlanRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    getTransformationProcessingStandardsRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/transformationPlans/processingStandards`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.ProcessingStandardPresentableFromJSON));
        });
    }
    /**
     */
    getTransformationProcessingStandards(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getTransformationProcessingStandardsRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     * Update a transformation plan
     */
    updateTransformationPlanRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling updateTransformationPlan.');
            }
            if (requestParameters.transformationPlanUpdateRequest === null || requestParameters.transformationPlanUpdateRequest === undefined) {
                throw new runtime.RequiredError('transformationPlanUpdateRequest', 'Required parameter requestParameters.transformationPlanUpdateRequest was null or undefined when calling updateTransformationPlan.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearerAuth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/transformationPlans/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.TransformationPlanUpdateRequestToJSON(requestParameters.transformationPlanUpdateRequest),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.TransformationPlanPresentableFromJSON(jsonValue));
        });
    }
    /**
     * Update a transformation plan
     */
    updateTransformationPlan(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.updateTransformationPlanRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
exports.TransformationPlanControllerApi = TransformationPlanControllerApi;
