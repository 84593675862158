"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class UserControllerApi extends runtime.BaseAPI {
    /**
     */
    checkPasswordRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.value === null || requestParameters.value === undefined) {
                throw new runtime.RequiredError('value', 'Required parameter requestParameters.value was null or undefined when calling checkPassword.');
            }
            const queryParameters = {};
            if (requestParameters.value !== undefined) {
                queryParameters['value'] = requestParameters.value;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/user/checkPassword`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.TextApiResponse(response);
        });
    }
    /**
     */
    checkPassword(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.checkPasswordRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    checkUserTokenExistsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.token === null || requestParameters.token === undefined) {
                throw new runtime.RequiredError('token', 'Required parameter requestParameters.token was null or undefined when calling checkUserTokenExists.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/user/{token}/onboarding`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.TextApiResponse(response);
        });
    }
    /**
     */
    checkUserTokenExists(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.checkUserTokenExistsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    getUserFromEmailAddressRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.email === null || requestParameters.email === undefined) {
                throw new runtime.RequiredError('email', 'Required parameter requestParameters.email was null or undefined when calling getUserFromEmailAddress.');
            }
            const queryParameters = {};
            if (requestParameters.email !== undefined) {
                queryParameters['email'] = requestParameters.email;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/user`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.UserPresentableFromJSON(jsonValue));
        });
    }
    /**
     */
    getUserFromEmailAddress(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getUserFromEmailAddressRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Finish the on-boarding phase of an invited user
     */
    postUserOnboardingRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.token === null || requestParameters.token === undefined) {
                throw new runtime.RequiredError('token', 'Required parameter requestParameters.token was null or undefined when calling postUserOnboarding.');
            }
            if (requestParameters.userOnboardingRequest === null || requestParameters.userOnboardingRequest === undefined) {
                throw new runtime.RequiredError('userOnboardingRequest', 'Required parameter requestParameters.userOnboardingRequest was null or undefined when calling postUserOnboarding.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/user/{token}/onboarding`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.UserOnboardingRequestToJSON(requestParameters.userOnboardingRequest),
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Finish the on-boarding phase of an invited user
     */
    postUserOnboarding(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.postUserOnboardingRaw(requestParameters, initOverrides);
        });
    }
    /**
     * Update an existing user
     */
    updateUserRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.userRequest === null || requestParameters.userRequest === undefined) {
                throw new runtime.RequiredError('userRequest', 'Required parameter requestParameters.userRequest was null or undefined when calling updateUser.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearerAuth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/user/update`,
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.UserRequestToJSON(requestParameters.userRequest),
            }, initOverrides);
            return new runtime.TextApiResponse(response);
        });
    }
    /**
     * Update an existing user
     */
    updateUser(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.updateUserRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    userInvitationRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.userOnboardingRequest === null || requestParameters.userOnboardingRequest === undefined) {
                throw new runtime.RequiredError('userOnboardingRequest', 'Required parameter requestParameters.userOnboardingRequest was null or undefined when calling userInvitation.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/user/invite`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.UserOnboardingRequestToJSON(requestParameters.userOnboardingRequest),
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     */
    userInvitation(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.userInvitationRaw(requestParameters, initOverrides);
        });
    }
}
exports.UserControllerApi = UserControllerApi;
