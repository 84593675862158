"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.JwtTokenToJSON = exports.JwtTokenFromJSONTyped = exports.JwtTokenFromJSON = void 0;
const runtime_1 = require("../runtime");
function JwtTokenFromJSON(json) {
    return JwtTokenFromJSONTyped(json, false);
}
exports.JwtTokenFromJSON = JwtTokenFromJSON;
function JwtTokenFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'token': !runtime_1.exists(json, 'token') ? undefined : json['token'],
    };
}
exports.JwtTokenFromJSONTyped = JwtTokenFromJSONTyped;
function JwtTokenToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'token': value.token,
    };
}
exports.JwtTokenToJSON = JwtTokenToJSON;
