"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserPresentableToJSON = exports.UserPresentableFromJSONTyped = exports.UserPresentableFromJSON = void 0;
const runtime_1 = require("../runtime");
function UserPresentableFromJSON(json) {
    return UserPresentableFromJSONTyped(json, false);
}
exports.UserPresentableFromJSON = UserPresentableFromJSON;
function UserPresentableFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'city': !runtime_1.exists(json, 'city') ? undefined : json['city'],
        'firstName': !runtime_1.exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !runtime_1.exists(json, 'lastName') ? undefined : json['lastName'],
    };
}
exports.UserPresentableFromJSONTyped = UserPresentableFromJSONTyped;
function UserPresentableToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'city': value.city,
        'firstName': value.firstName,
        'lastName': value.lastName,
    };
}
exports.UserPresentableToJSON = UserPresentableToJSON;
