"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableCertificationPresentableToJSON = exports.TableCertificationPresentableFromJSONTyped = exports.TableCertificationPresentableFromJSON = exports.TableCertificationPresentableSubjectEnum = exports.TableCertificationPresentableStatusEnum = void 0;
const runtime_1 = require("../runtime");
/**
* @export
* @enum {string}
*/
var TableCertificationPresentableStatusEnum;
(function (TableCertificationPresentableStatusEnum) {
    TableCertificationPresentableStatusEnum["Pending"] = "PENDING";
    TableCertificationPresentableStatusEnum["Accepted"] = "ACCEPTED";
    TableCertificationPresentableStatusEnum["Refused"] = "REFUSED";
})(TableCertificationPresentableStatusEnum = exports.TableCertificationPresentableStatusEnum || (exports.TableCertificationPresentableStatusEnum = {})); /**
* @export
* @enum {string}
*/
var TableCertificationPresentableSubjectEnum;
(function (TableCertificationPresentableSubjectEnum) {
    TableCertificationPresentableSubjectEnum["Scope"] = "SCOPE";
    TableCertificationPresentableSubjectEnum["Transaction"] = "TRANSACTION";
    TableCertificationPresentableSubjectEnum["Material"] = "MATERIAL";
    TableCertificationPresentableSubjectEnum["Self"] = "SELF";
})(TableCertificationPresentableSubjectEnum = exports.TableCertificationPresentableSubjectEnum || (exports.TableCertificationPresentableSubjectEnum = {}));
function TableCertificationPresentableFromJSON(json) {
    return TableCertificationPresentableFromJSONTyped(json, false);
}
exports.TableCertificationPresentableFromJSON = TableCertificationPresentableFromJSON;
function TableCertificationPresentableFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
        'contractorName': !runtime_1.exists(json, 'contractorName') ? undefined : json['contractorName'],
        'consigneeName': !runtime_1.exists(json, 'consigneeName') ? undefined : json['consigneeName'],
        'approverName': !runtime_1.exists(json, 'approverName') ? undefined : json['approverName'],
        'validFrom': !runtime_1.exists(json, 'validFrom') ? undefined : (new Date(json['validFrom'])),
        'validUntil': !runtime_1.exists(json, 'validUntil') ? undefined : (new Date(json['validUntil'])),
        'documentType': !runtime_1.exists(json, 'documentType') ? undefined : json['documentType'],
        'status': !runtime_1.exists(json, 'status') ? undefined : json['status'],
        'certificateReferenceNumber': !runtime_1.exists(json, 'certificateReferenceNumber') ? undefined : json['certificateReferenceNumber'],
        'assessmentType': !runtime_1.exists(json, 'assessmentType') ? undefined : json['assessmentType'],
        'referencedStandard': !runtime_1.exists(json, 'referencedStandard') ? undefined : json['referencedStandard'],
        'subject': !runtime_1.exists(json, 'subject') ? undefined : json['subject'],
    };
}
exports.TableCertificationPresentableFromJSONTyped = TableCertificationPresentableFromJSONTyped;
function TableCertificationPresentableToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'contractorName': value.contractorName,
        'consigneeName': value.consigneeName,
        'approverName': value.approverName,
        'validFrom': value.validFrom === undefined ? undefined : (value.validFrom.toISOString()),
        'validUntil': value.validUntil === undefined ? undefined : (value.validUntil.toISOString()),
        'documentType': value.documentType,
        'status': value.status,
        'certificateReferenceNumber': value.certificateReferenceNumber,
        'assessmentType': value.assessmentType,
        'referencedStandard': value.referencedStandard,
        'subject': value.subject,
    };
}
exports.TableCertificationPresentableToJSON = TableCertificationPresentableToJSON;
