"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MaterialControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class MaterialControllerApi extends runtime.BaseAPI {
    /**
     * Create a new material
     */
    addMaterialFromCompanyRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.materialRequest === null || requestParameters.materialRequest === undefined) {
                throw new runtime.RequiredError('materialRequest', 'Required parameter requestParameters.materialRequest was null or undefined when calling addMaterialFromCompany.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearerAuth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/materials/create`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.MaterialRequestToJSON(requestParameters.materialRequest),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.MaterialPresentableFromJSON(jsonValue));
        });
    }
    /**
     * Create a new material
     */
    addMaterialFromCompany(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.addMaterialFromCompanyRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    getMaterialsByCompanyRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.company === null || requestParameters.company === undefined) {
                throw new runtime.RequiredError('company', 'Required parameter requestParameters.company was null or undefined when calling getMaterialsByCompany.');
            }
            if (requestParameters.isInput === null || requestParameters.isInput === undefined) {
                throw new runtime.RequiredError('isInput', 'Required parameter requestParameters.isInput was null or undefined when calling getMaterialsByCompany.');
            }
            if (requestParameters.isForTransformation === null || requestParameters.isForTransformation === undefined) {
                throw new runtime.RequiredError('isForTransformation', 'Required parameter requestParameters.isForTransformation was null or undefined when calling getMaterialsByCompany.');
            }
            const queryParameters = {};
            if (requestParameters.company !== undefined) {
                queryParameters['company'] = requestParameters.company;
            }
            if (requestParameters.isInput !== undefined) {
                queryParameters['isInput'] = requestParameters.isInput;
            }
            if (requestParameters.isForTransformation !== undefined) {
                queryParameters['isForTransformation'] = requestParameters.isForTransformation;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/materials`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.MaterialPresentableFromJSON));
        });
    }
    /**
     */
    getMaterialsByCompany(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getMaterialsByCompanyRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
exports.MaterialControllerApi = MaterialControllerApi;
