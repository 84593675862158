"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderPositionToJSON = exports.OrderPositionFromJSONTyped = exports.OrderPositionFromJSON = void 0;
const runtime_1 = require("../runtime");
const ContractPosition_1 = require("./ContractPosition");
const Material_1 = require("./Material");
const OrderTrade_1 = require("./OrderTrade");
const Unit_1 = require("./Unit");
function OrderPositionFromJSON(json) {
    return OrderPositionFromJSONTyped(json, false);
}
exports.OrderPositionFromJSON = OrderPositionFromJSON;
function OrderPositionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
        'contractorMaterial': !runtime_1.exists(json, 'contractorMaterial') ? undefined : Material_1.MaterialFromJSON(json['contractorMaterial']),
        'consigneeMaterial': !runtime_1.exists(json, 'consigneeMaterial') ? undefined : Material_1.MaterialFromJSON(json['consigneeMaterial']),
        'weight': !runtime_1.exists(json, 'weight') ? undefined : json['weight'],
        'quantity': !runtime_1.exists(json, 'quantity') ? undefined : json['quantity'],
        'externalDescription': !runtime_1.exists(json, 'externalDescription') ? undefined : json['externalDescription'],
        'unit': !runtime_1.exists(json, 'unit') ? undefined : Unit_1.UnitFromJSON(json['unit']),
        'contractPosition': !runtime_1.exists(json, 'contractPosition') ? undefined : ContractPosition_1.ContractPositionFromJSON(json['contractPosition']),
        'orderTrade': !runtime_1.exists(json, 'orderTrade') ? undefined : OrderTrade_1.OrderTradeFromJSON(json['orderTrade']),
    };
}
exports.OrderPositionFromJSONTyped = OrderPositionFromJSONTyped;
function OrderPositionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'contractorMaterial': Material_1.MaterialToJSON(value.contractorMaterial),
        'consigneeMaterial': Material_1.MaterialToJSON(value.consigneeMaterial),
        'weight': value.weight,
        'quantity': value.quantity,
        'externalDescription': value.externalDescription,
        'unit': Unit_1.UnitToJSON(value.unit),
        'contractPosition': ContractPosition_1.ContractPositionToJSON(value.contractPosition),
        'orderTrade': OrderTrade_1.OrderTradeToJSON(value.orderTrade),
    };
}
exports.OrderPositionToJSON = OrderPositionToJSON;
